import { Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { Box, Button } from '@mui/material';
import React, { useContext } from 'react';
import AuthContext from '../context/auth/AuthContext';
import { ifMainColorIsLight } from '../helpers/functions';
import { ReactComponent as ProfileIcon } from './../assets/icons/user.svg';
import { Link, Link as RouterLink } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { alpha } from '@mui/material';
import PageContext from '../context/page/PageContext';

const GreetingsHeader = ({ title, subtitle }) => {
  const { user } = useContext(AuthContext);
  const { page } = useContext(PageContext);
  const theme = useTheme();

  return (
    <Stack display="flex" direction="column" width="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{ mb: 3 }}
      >
        <Box>
          <Button
            component={Link}
            to="/"
            sx={{
              width: { xs: '100%', sm: 250 },
              maxWidth: { xs: 160, sm: 250 },
              height: { xs: 56, sm: 86 },
              p: 0,
              pr: 1,
              display: 'block',
            }}
          >
            <img
              alt="Remy Sharp"
              src={page.logo}
              variant="square"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'left ',
                display: 'block',
              }}
            />
          </Button>
        </Box>

        <Stack direction="row" spacing={'15px'}>
          <Tooltip title="Profile">
            <Button
              size="small"
              component={RouterLink}
              to="/profile"
              sx={{
                padding: '10px',
                minWidth: 'unset',
                borderRadius: '50%',
                backgroundColor: alpha(
                  ifMainColorIsLight(theme),
                  0.1
                ),
                color: alpha(ifMainColorIsLight(theme), 0.7),
                // boxShadow: '0px 0px 6px rgba(0,0,0, 0.1)',
                '& svg': {
                  color: alpha(ifMainColorIsLight(theme), 0.7),
                  fill: alpha(ifMainColorIsLight(theme), 0.7),
                  width: '20px',
                  height: '20px',
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: ifMainColorIsLight(theme),
                },
                '&:hover svg': {
                  color: ifMainColorIsLight(theme),
                  fill: ifMainColorIsLight(theme),
                },
              }}
            >
              <ProfileIcon fontSize="small" />
            </Button>
          </Tooltip>

          <Tooltip title="Logout">
            <Button
              size="small"
              variant="text"
              onClick={() => window.logout()}
              sx={{
                padding: '8px',
                minWidth: 'unset',
                borderRadius: '50%',

                backgroundColor: alpha(
                  theme.palette.iconDarkDefault.main,
                  0.6
                ),
                color: theme.palette.common.white,
                // boxShadow: '0px 0px 6px rgba(0,0,0, 0.1)',
                '& svg': {
                  color: theme.palette.common.white,
                  fill: theme.palette.common.white,
                  width: '22px',
                  height: '22px',
                  fontSize: '20px',
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: theme.palette.iconDarkDefault.main,
                },
                '&:hover svg': {
                  color: theme.palette.iconDarkDefault.main,
                  fill: theme.palette.iconDarkDefault.main,
                },
              }}
            >
              <LogoutIcon inheritViewBox fontSize={'20px'} />
            </Button>
          </Tooltip>
        </Stack>
      </Box>
      <Box>
        <Typography
          component="h1"
          variant="h1"
          sx={{
            fontSize: '35px',
            lineHeight: '1.25em',
            fontWeight: '800',
            textTransform: 'capitalize',
          }}
        >
          {!title ? `Hello, ${user.first_name}` : title}
        </Typography>
        <Typography
          component="p"
          sx={{
            fontFamily: '"Poppins", sans-serif',
            fontSize: { xs: '14px', md: '22px' },
          }}
          color="#959292"
        >
          {!subtitle ? 'Keep Moving & Stay Healthy' : subtitle}
        </Typography>
      </Box>
    </Stack>
  );
};

export default GreetingsHeader;
