import React, { useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import AuthContext from './context/auth/AuthContext';
import AppOutlet from './components/AppOutlet';
function RequireAuth() {
	const { user } = useContext(AuthContext);

	let location = useLocation();
	if (!user) {
		return (
			<Navigate to='/login' state={{ from: location }} replace />
		);
	}
	return <AppOutlet padding='68px' />;
}

export default RequireAuth;
