import {
  Box,
  Button,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import AuthContext from '../context/auth/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const identifier = window.location.hostname;
const TempPassword = () => {
  let navigate = useNavigate();
  const { user, apiUrl ,token} = useContext(AuthContext);
 
  const [state, setState] = useState({
    user: user,
    password: '',
    password_confirmation: '',
    token: token,
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    axios
      .put(`${apiUrl}/v1/client/change-password`,   {
        password: state.password,
        password_confirmation: state.password_confirmation,        
        domain: window.location.hostname,
      },{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
      .then((res) => {
      
        if (res.data.data.status === false) {
          toast.error(res.data.data.message);
        } else {
          toast.success('Password updated successfully!');
        }
     
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((res) => {
        const responseData = res.response.data;
				//show errors[] or message
				if(responseData.errors){
					const errorMessages = [];
					for (const key in responseData.errors) {
						if (responseData.errors.hasOwnProperty(key)) {
						const fieldErrors = responseData.errors[key];
						for (let i = 0; i < fieldErrors.length; i++) {
							//errorMessages.push(fieldErrors[i]);
              toast.error(fieldErrors[i]);
						}
						}
					}
  				
				}else if(responseData.error){
           toast.error(responseData.error);
        }
				else {
					toast.error( responseData.message);
				}
      });
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        direction="column"
        spacing={'5px'}
        sx={{
          maxWidth: '400px',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Typography component="h2">Reset Password</Typography>
        <Typography
          component="p"
          sx={{ fontSize: '14px' }}
          color={'iconDarkDefault.main'}
        >
          Hi {user ? user.email : ''}, do you want to reset your
          password? Fill the fields below and tap the "Reset Password"
          button.{' '}
        </Typography>
        <Stack
          direction="column"
          spacing={'10px'}
          sx={{ padding: '10px 20px', justifyContent: 'center' }}
        >
          <TextField
            margin="none"
            required
            name="password"
            label="Password"
            type="password"
            id="password"
            value={state.password}
            onChange={handleChange}
            autoComplete="current-password"
            size="small"
            // sx={[TextFieldStyle, { mb: { xs: '10px', md: 2 } }]}
          />

          <TextField
            margin="none"
            required
            name="password_confirmation"
            label="Confirm Password"
            type="password"
            id="password_confirmation"
            value={state.password_confirmation}
            onChange={handleChange}
            autoComplete="password_confirmation"
            size="small"
            // sx={[TextFieldStyle, { mb: { xs: '10px', md: 2 } }]}
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            onClick={handleSubmit}
          >
            Reset Password
          </Button>
        </Stack>

        <Typography
          component="p"
          sx={{ fontSize: '14px' }}
          color={'muted.main'}
        >
          If you didn't request this, you can delete this message and
          carry on as you were
        </Typography>
      </Stack>
    </Box>
  );
};

export default TempPassword;
