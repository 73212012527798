import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Button,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  alpha,
} from '@mui/material';
import AuthContext from '../../context/auth/AuthContext';
import Legal from '../../components/Legal';
import EmailIcon from '@mui/icons-material/Email';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTheme } from '@emotion/react';
import PageContext from '../../context/page/PageContext';

export default function Login() {
  const theme = useTheme();
  const context = useContext(AuthContext);
  const {  page } = useContext(PageContext);
  const [state, setState] = useState({
    email: '',
    password: '',
    domain: window.location.hostname
  });
  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validate = () => {
    setErrors([]);
    let newErrors = [];
    if (!state.email) {
      newErrors.push({
        name: 'email',
        error: 'The Email Field is Required',
      });
    }
    //email format validation
    if (state.email && !regexEmail.test(state.email)) {
      newErrors.push({
        name: 'email',
        error: 'The Email field is invalid (e.g. email@domain.com).',
      });
    }
    if (!state.password) {
      newErrors.push({
        name: 'password',
        error: 'The Password Field is Required',
      });
    }

    // newErrors.forEach((x) => {
    //   toast.error(x.error);
    // });
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();
    if (errors.length > 0) {
      setErrors(errors);
    } else {
      context.login(state);
    }
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const btnStyle = {
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: { xs: '16px', md: '35px' },
    lineSeight: '100%',
    letterSpacing: '0.24px',
    borderRadius: '50px',
    height: { xs: '64px', md: '98px' },
    textTransform: 'none',
  };

  const TextFieldStyle = {
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
      borderRadius: '50px',
      borderColor: '#fff',
      height: { xs: '50px', md: '70px' },
      pl: { xs: '22px', md: '30px' },
      alignItems: 'flex-end',
      pb: { xs: '7px', md: '15px' },
      '& legend': { display: 'none' },
      '& .MuiOutlinedInput-notchedOutline': {
        top: 0,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&:hover svg': {
        color: 'primary.main',
      },
    },
    '& .MuiFormLabel-root': {
      top: { xs: '10px', md: '14px' },
      left: { xs: '8px', md: '17px' },
    },
    '& .Mui-focused': {
      borderColor: 'transparent',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent!important',
      },
    },
    '& .MuiInputBase-input': {
      p: '0',
    },
  };

  return (
    <Grid
      container
      sx={{
        flexGrow: '1',
        position: 'relative',
        alignItems: 'stretch',
        height: '100%',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'flex-end',
      }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        lg={7}
        sx={{
          position: { xs: 'fixed', sm: 'relative' },
          bottom: { xs: '0px', sm: 'initial' },
          width: { xs: '100%', sm: 'initial' },
          backgroundImage:
            'url(https://source.unsplash.com/random?fitness)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: { xs: '100vh', sm: 'auto' },
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          position: 'relative',
          zIndex: 1,
          background: {
            xs: `linear-gradient(180deg,  ${alpha(
              theme.palette.blackBg.main,
              0
            )} 0%, ${theme.palette.blackBg.main} 47.84%)`,

            sm: theme.palette.common.black,
          },
          boxShadow: 'none',
          display: 'flex',
          alignItems: { xs: 'flex-end', sm: 'center' },
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexGrow: '1',
            height: { xs: 'auto', sm: '100%' },
          }}
        >
          <Box
            sx={{
              mx: { xs: '20px', md: 4 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: '1',
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                my: 8,
                mb: { xs: '12px', md: '64px' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexGrow: '0',
                maxWidth: '513px',
                width: '100%',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography
                  component="h1"
                  variant="h1"
                  sx={{
                    color: 'white.main',
                    fontSize: { xs: '40px', md: '53px' },
                  }}
                  align="center"
                  mb={{ xs: 1, md: '7vh' }}
                >
                  Sign In
                </Typography>
              </Box>
              <Box sx={{ width: '100%', mt: '2rem' }}>
                <TextField
                  margin="none"
                  required
                  error={
                    errors.find((x) => x.name === 'email')
                      ? true
                      : false
                  }
                  helperText={
                    errors.find((x) => x.name === 'email')
                      ? errors.find((x) => x.name === 'email').error
                      : null
                  }
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                  autoComplete="email"
                  autoFocus
                  InputProps={{
                    startAdornment: <EmailIcon sx={{ mr: 2 }} />,
                  }}
                  sx={[TextFieldStyle, { mb: { xs: '10px', md: 2 } }]}
                />
                <TextField
                  margin="none"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={state.password}
                  onChange={handleChange}
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: <LockOpenIcon sx={{ mr: 2 }} />,
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                  error={
                    errors.find((x) => x.name === 'password')
                      ? true
                      : false
                  }
                  helperText={
                    errors.find((x) => x.name === 'password')
                      ? errors.find((x) => x.name === 'password')
                          .error
                      : null
                  }
                  sx={[TextFieldStyle, { mb: { xs: '10px', md: 2 } }]}
                />

                {/* <FormControlLabel
							control={<Checkbox value='remember' color='primary' />}
							label='Remember me'
						/> */}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={[btnStyle, { mb: { xs: '20px', md: 3 } }]}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs={12} sm={5}>
                    <Link
                      component={RouterLink}
                      to="/forgot"
                      variant="body2"
                      color="white.secondary"
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  {page && page.access_register_page && 
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                    >
                      <Link
                        component={RouterLink}
                        to="/sign-up"
                        variant="body2"
                        color="white.secondary"
                      >
                        {"Don't have an account? Register"}
                      </Link>
                    </Grid>
                    }
                </Grid>
              </Box>
              {/* <Link component={RouterLink} to="/" variant="body2">
              Go to Homepage
            </Link> */}
            </Box>
          </Box>

          <Box
            sx={{
              my: 1,
              mx: 3,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'end',
            }}
          >
            <Legal />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
