import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AuthProvider } from './context/auth/AuthContext';
import { PageProvider } from './context/page/PageContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <PageProvider>
    <AuthProvider>
      <App />
    </AuthProvider>
  </PageProvider>
  // <React.StrictMode>
  // </React.StrictMode>
);
serviceWorkerRegistration.register();

// Code to handle install prompt on desktop

let deferredPrompt;
const installContainer = document.getElementById(
  'installPromptContainer'
);
const addBtn = document.querySelector('.add-button');
const dismiss = document.getElementById('dismiss-button');

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI to notify the user they can add to home screen
  installContainer.classList.add('installPrompt');
});

addBtn.addEventListener('click', () => {
  // hide our user interface that shows our A2HS button
  installContainer.style.display = 'none';
  // Show the prompt
  deferredPrompt.prompt();
  // Wait for the user to respond to the prompt
  deferredPrompt.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the A2HS prompt');
    } else {
      console.log('User dismissed the A2HS prompt');
    }
    deferredPrompt = null;
  });
});

dismiss.addEventListener('click', () => {
  installContainer.style.display = 'none';
});
