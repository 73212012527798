import React from 'react';
import { Grid, Typography, Box, Container } from '@mui/material';

function About() {
  return (
    <Box
      sx={{
        flexGrow: '1',
        position: 'relative',
        alignItems: 'stretch',
        height: '100%',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'flex-end',
      }}
    >
      {/* end of overlay */}
      <Container
        maxWidth="lg"
        sx={{
          position: 'relative',
          marginBottom: '1rem',
          py: 4,
        }}
      >
        <Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: { xs: '1.5rem', lg: '2rem' },
                lineHeight: '1.25em',
                fontWeight: '800',
                mb: 3,
              }}
            >
              About Us
            </Typography>
            <Typography variant="body2" gutterBottom>
              Lorem, ipsum dolor sit amet consectetur adipisicing
              elit. Facere vel repellat sit, adipisci modi saepe sint
              voluptatibus doloremque amet aut quos tempora doloribus
              dolorem mollitia unde impedit officia alias dicta.Lorem,
              ipsum dolor sit amet consectetur adipisicing elit.
              Facere vel repellat sit, adipisci modi saepe sint
              voluptatibus doloremque amet aut quos tempora doloribus
              dolorem mollitia unde impedit officia alias dicta.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Lorem, ipsum dolor sit amet consectetur adipisicing
              elit. Facere vel repellat sit, adipisci modi saepe sint
              voluptatibus doloremque amet aut quos tempora doloribus
              dolorem mollitia unde impedit officia alias dicta.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Lorem, ipsum dolor sit amet consectetur adipisicing
              elit. Facere vel repellat sit, adipisci modi saepe sint
              voluptatibus doloremque amet aut quos tempora doloribus
              dolorem mollitia unde impedit officia alias dicta.Lorem,
              ipsum dolor sit amet consectetur adipisicing elit.
              Facere vel repellat sit, adipisci modi saepe sint
              voluptatibus doloremque amet aut quos tempora doloribus
              dolorem mollitia unde impedit officia alias dicta.Lorem,
              ipsum dolor sit amet consectetur adipisicing elit.
              Facere vel repellat sit, adipisci modi saepe sint
              voluptatibus doloremque amet aut quos tempora doloribus
              dolorem mollitia unde impedit officia alias dicta.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default About;
