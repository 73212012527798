import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import {
  Skeleton,
  Grow,
  Card,
  CardContent,
  Chip,
  Box,
  Stack,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import VideoDetails from './VideoDetails';
import VideoDetailsMobile from './VideoDetailsMobile';

import { styled, useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material';

function VideoItem({
  vid,
  isRecent,
  progress,
  parentCallback,
  pageType,
  height = '100%',
  style,
}) {
  //   const playerRef = useRef(null);
  //set the dialog state
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [watchedVideos, setWatchedVideos] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [duration, setDuration] = useState(100);

  //detect sm breakPoint
  const breakPointMD = useMediaQuery(theme.breakpoints.down('md'));

  const playerRef = React.useRef();

  //save the state of the video that is splaying
  const [videoState, setVideoState] = useState({
    id: false,
    progress: 0,
    progressEnd: 100,
    timestamp: 0,
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    //send recently watched videos to parent in order to refresh video list
    if (parentCallback && watchedVideos.length > 0) {
      parentCallback(watchedVideos);
    }
  };

  // keep video seek state at it was leaved
  const onReady = React.useCallback(() => {
    setIsReady(true);

    //get current video id
    const videoID = playerRef.current.props.dataid;

    let obj = findStoredVideo(videoID);

    let time = 0;
    if (obj.progress) {
      if (obj.progress >= obj.progressEnd) {
        time = 0;
      } else {
        time = obj.progress;
      }
    } else {
      time = 0;
    }

    //set seekbar to current progress from stored videos
    playerRef.current.seekTo(time, 'seconds');
  }, [isReady]);

  //search the id in stored videos
  const findStoredVideo = (videoID) => {
    //get all watched videos
    const allVideos = JSON.parse(
      localStorage.getItem('videosWatched')
    );
    return allVideos.find((item) => item.id === videoID);
  };

  //action on videostate changing
  useEffect(() => {
    if (videoState.id) {
      let localStorageVideos = [];

      if (
        localStorage.getItem('videosWatched') === 'undefined' ||
        localStorage.getItem('videosWatched') === null
      ) {
        //if somehow this gets undefined, reset localstorage item
        localStorage.removeItem('videosWatched');
      } else if (localStorage.getItem('videosWatched')) {
        //if the videos are in local storage

        //get videos from local storage
        localStorageVideos = JSON.parse(
          localStorage.getItem('videosWatched')
        );
      }

      if (localStorageVideos.length < 1) {
        //if local storage has no videos, add curent video
        localStorageVideos.push(videoState);
      } else {
        //here you set the maximum videos that should be stored
        if (localStorageVideos.length > 5) {
          localStorageVideos.shift(); //removes the first video
        }

        localStorageVideos = renderDuplicates(
          localStorageVideos,
          videoState
        );
      }

      //set local storage videos
      localStorage.setItem(
        'videosWatched',
        JSON.stringify(localStorageVideos)
      );
      // set watched videos to send to parent in order to update them when they change (new video was played)
      setWatchedVideos(localStorageVideos);
    }
  }, [videoState]);

  const renderDuplicates = (localStorageVideos) => {
    let storedVideos = localStorageVideos;

    //find if there is a video with the same id
    let obj = storedVideos.find((item) => item.id === videoState.id);
    let index = storedVideos.indexOf(obj);

    if (obj) {
      storedVideos[index] = videoState;
    } else {
      //if there is not video with same id, add it to array
      storedVideos.push(videoState);
    }
    //if local storage has videos , filter them to avoid adding dupplicates
    localStorageVideos = storedVideos;
    return localStorageVideos;
  };

  //css
  const playerContainer = {
    height: {
      xs: pageType === 'schedule' ? '300px' : '250px',
      sn: pageType === 'schedule' ? '318px' : '250px',
      md: '250px',
      lg: '250px',
      xl: '318px',
    },
    width: '100%',
    position: 'relative',
  };
  const playerThumbnail = {
    height: '100%',
    width: '100%',
    '& div': {
      '& div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& iframe': {
          width: '593px!important',
          minWidth: '100%',
          minHeight: '100%',
        },
      },
    },
  };

  const handleVideoChildData = async (childData) => {
    if (childData) {
      setOpen(childData);
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', ...style }}>
        <Card
          className="video-card"
          title={vid.title}
          sx={{
            boxShadow: 'none',
            borderRadius: {
              xs: pageType === 'schedule' ? '24px' : '12px',
              md: '12px',
            },
            height: '100%',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              p: '0!important',
            }}
            className="card-content-video"
          >
            <Box sx={playerContainer}>
              {pageType === 'schedule' && breakPointMD ? (
                ''
              ) : (
                <div className="play-button">
                  <IconButton
                    aria-label="play/pause"
                    onClick={handleClickOpen}
                    sx={{
                      backgroundColor: alpha(
                        theme.palette.primary.main,
                        0.43
                      ),
                      '&::before': {
                        border: '1px solid',
                        borderColor: 'primary.main',
                      },
                    }}
                  >
                    <PlayArrowIcon
                      sx={{
                        height: { xs: 38, md: 44, lg: 50, xl: 60 },
                        width: { xs: 38, md: 44, lg: 50, xl: 60 },
                      }}
                    />
                  </IconButton>
                </div>
              )}
              <Box className="player" sx={playerThumbnail}>
                <ReactPlayer
                  url={`https://www.vimeo.com/${vid.vimeo_id}`}
                  controls={false}
                  width="100%"
                  height={height}
                  ref={playerRef}
                  light={true}
                  playIcon={' '}

                  // onReady={handleReady}
                />
              </Box>
            </Box>

            {breakPointMD && pageType === 'schedule' ? (
              <></>
            ) : (
              <VideoDetails vid={vid} />
            )}
          </CardContent>
          {isRecent ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress
                variant="determinate"
                value={progress}
              />
            </Box>
          ) : (
            ''
          )}

          {breakPointMD && pageType === 'schedule' ? (
            <VideoDetailsMobile
              vid={vid}
              videoDialog={handleVideoChildData}
            />
          ) : (
            <></>
          )}
        </Card>
      </Box>
      <Dialog
        fullScreen={true}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': { backgroundColor: 'primary.main' },
        }}
      >
        <DialogContent
          sx={{
            padding: 0,
            '&:hover .vidTitle,&:active .vidTitle': {
              opacity: 1,
            },
          }}
        >
          <IconButton
            variant="outlined"
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
              zIndex: 9999999,
              borderRadius: '0',
              color: '#fff',
              backgroundColor: '#000',

              '& svg': {
                fontSize: '3rem',
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {/* video title */}
          {/* <Box
            className="vidTitle"
            sx={{
              width: '90%',
              ml: '5%',
              py: 3,
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: 4,
              borderRadius: '0',
              color: '#fff',
              backgroundColor: 'rgba(0,0,0,0.3)',
              opacity: 0,
              transition: ' all 0.3s ease-in',
              pl: 3,
            }}
          >
            <Typography component="h2">{vid.title}</Typography>
          </Box> */}
          <ReactPlayer
            url={`https://www.vimeo.com/${vid.vimeo_id}`}
            controls={true}
            width="90%"
            height="100%"
            style={{ margin: 'auto' }}
            pip={true}
            stopOnUnmount={false}
            playsInline={true}
            // onStart={this.handlePlay}

            onProgress={(barprogress) => {
              if (open && isReady) {
                setVideoState({
                  ...videoState,
                  id: vid.id,
                  progress: barprogress.playedSeconds,
                  timestamp: moment().valueOf(),
                  progressEnd: duration,
                });
              }
            }}
            onDuration={(duration) => {
              if (open && isReady) {
                setDuration(duration);
              }
            }}
            ref={playerRef}
            playing={isReady}
            onReady={onReady}
            dataid={vid.id}

            // ref={playerRef}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default VideoItem;
