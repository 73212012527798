import React, { useState, useEffect, useContext } from 'react';
import GreetingsHeader from '../../components/GreetingsHeader';
import {
  alpha,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';
import VideoItem from '../../components/videoitem/VideoItem';
import AuthContext from '../../context/auth/AuthContext';
import axios from 'axios';
import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import ConfirmDialog from '../../components/ConfirmDialog';

import ScheduleMobile from './components/ScheduleMobile';

import { isLight, ifMainColorIsLight } from '../../helpers/functions';

let start = moment().subtract(2, 'days');
let end = moment().add(7, 'days');

var daysArray = [];
var day = start;

const identifier = window.location.hostname;

while (day <= end) {
  daysArray.push({
    day: day.format('D'),
    date: day,
    name: day.format('dddd'),
    full: day.format('dddd, MMMM Do YYYY'),
  });
  day = day.clone().add(1, 'd');
}

function Schedule() {
  const authContext = useContext(AuthContext);

  const theme = useTheme();

  const { apiUrl, token } = useContext(AuthContext);

  const [activeDay, setActiveDay] = useState(moment().format('D'));
  const [videos, setVideos] = useState({
    selection: [],
    upcoming: [],
  });

  //deleteScheduled
  const [deleteScheduledVideo, setDeleteScheduledVideo] =
    useState(false);

  const [dialogConfirmData, setDialogConfirmData] = useState([]);
  const [extractedVideos, setExtractedVideos] = useState([]);
  const [allTodayVideos, setAllTodayVideos] = useState([]);

  const [upcomingVideos, setUpcomingVideos] = useState([]);

  //loaders
  const [extractedVideosLoader, setExtractedVideosLoader] =
    useState(true);
  const [upcomingVideosLoader, setUpcomingVideosLoader] =
    useState(true);

  const [mobileLoader, setMobileLoader] = useState(true);
  useEffect(() => {
    setExtractedVideosLoader(true);
    setMobileLoader(true);
    getVideos(activeDay);
  }, [activeDay]);

  const getVideos = (day) => {
    let formatDay = moment(
      daysArray.find((x) => x.day === day).date
    ).format('YYYY-MM-DD');
    axios
      .get(
        `${apiUrl}/v1/client/video-schedule/schedule`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            domain: identifier,
            date: formatDay,
          },
        }
      )
      .then((x) => {
        let videos = x.data.data;
        setVideos({ ...videos });

        if (videos.selection.length === 0) {
          setExtractedVideosLoader(false);
          setMobileLoader(false);
        }
        if (videos.upcoming.length === 0) {
          setUpcomingVideosLoader(false);
        }
      }).catch((err) => {
      if(err.response.status === 401){
        authContext.logout();
        toast.error('Session expired, please login again');
      }
    });
  };

  const extractSelectionVideos = () => {
    let videosArray = [];
    videos.selection.forEach((x) => {
      let toArray = Object.entries(x.videos);
      toArray.forEach((vidArr, x) => {
        if (vidArr && vidArr[1]) {
          videosArray = [...videosArray, ...vidArr[1]];
        }
      });
    });
    setAllTodayVideos(videosArray);

    setExtractedVideos(videosArray);

    if (videosArray.length > 0) {
      setExtractedVideosLoader(false);
    }
  };

  useEffect(() => {
    extractSelectionVideos();
    extractUpcomingVideos();
  }, [videos]);

  //set todays/selected day videos
  const extractUpcomingVideos = () => {
    let videosArray = [];

    videos.upcoming.forEach((x) => {
      let toArray = Object.entries(x.videos);
      toArray.forEach((vidArr, y) => {
        if (vidArr && vidArr[1]) {
          let match = videosArray.find(
            (item) => item.date === x.date
          );
          if (match) {
            match.videos = [...match.videos, ...vidArr[1]];
          } else {
            videosArray.push({
              date: x.date,
              videos: [...vidArr[1]],
            });
          }
        }
      });
    });

    let sortedVideos = videosArray.sort(
      (a, b) =>
        new Date(...a.date.split('/').reverse()) -
        new Date(...b.date.split('/').reverse())
    );

    setUpcomingVideos(sortedVideos);
    if (sortedVideos.length > 0) {
      setUpcomingVideosLoader(false);
    }
  };

  //get deleteData from child (scheduleMobile)
  const handleCallbackMobileData = async (childData) => {
    if (childData) {
      setDeleteScheduledVideo(childData);
    }
  };

  //if there is a video_id in state, show dialog
  useEffect(() => {
    if (deleteScheduledVideo && deleteScheduledVideo.scheduled_id) {
      //after dialog confirmation => onDialogConfirm
      setDialogConfirmData({
        message: (
          <Typography>
            Are you sure you want to delete
            <strong> {deleteScheduledVideo.video_title} </strong>,
            scheduled on &nbsp;
            <strong> {deleteScheduledVideo.scheduled_date} </strong>
            at
            <strong> {deleteScheduledVideo.hour}</strong>?
          </Typography>
        ),
        onSubmit: onDialogConfirm,
        onCancel: OnDialogCancel,
      });
    }
  }, [deleteScheduledVideo]);

  //reset schedule dialog
  const OnDialogCancel = () => {
    setDialogConfirmData([]);
    setDeleteScheduledVideo(false);
  };

  //api to delete scheduled
  const onDialogConfirm = () => {
    //delete saved video API
    axios
      .delete(`${apiUrl}/v1/client/video-schedule/delete/${deleteScheduledVideo.scheduled_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { domain: identifier },
      })
      .then((res) => {
        toast.success(`Video Removed!`);

        //reset dialog in order to close
        OnDialogCancel();
        //reset the scheduled videos and load them again to refresh list
        getVideos(activeDay);
      })
      .catch((err) => {
        if(err.response.status === 401){
          authContext.logout();
          toast.error('Session expired, please login again');
        }else{
          console.error(err.message);
          toast.error(err.message);
        }
      });
  };

  //-----------ScheduledMobile ----------------

  //detect sm breakPoint
  const breakPointSM = useMediaQuery(theme.breakpoints.down('md'));
  //-------------------------------------------

  return (
    <>
      <Box
        maxWidth={null}
        sx={{
          px: { xs: '27px', md: '50px' },
          marginLeft: 'unset',
        }}
      >
        <Box
          sx={{
            color: '#181818',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: { md: 'space-between' },
            fontSize: '.9em',
            position: 'relative',
            px: 0,
            pt: 3,
            pb: '81px',
          }}
        >
          <GreetingsHeader />
        </Box>
        {/* User Avatar */}
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                mb: '2em',
                justifyContent: { xs: 'center', sm: 'start' },
              }}
            >
              <Typography
                component="p"
                variant="h1"
                sx={{
                  fontSize: '1.8em',
                  letterSpacing: ' 0.15px',
                }}
              >
                Schedule
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* Blue box days */}
        <Grid
          container
          spacing={{ xs: 1, sm: 1.5, md: 2 }}
          sx={{ justifyContent: { xs: 'center', sm: 'start' } }}
        >
          {daysArray.map((x, i) => (
            <Grid item key={i}>
              <Button
                key={i}
                variant={
                  activeDay !== x.day ? 'contained' : 'outlined'
                }
                className={activeDay === x.day ? 'selected' : ''}
                onClick={() => setActiveDay(x.day)}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  textAlign: 'center',
                  padding: '0px',
                  minWidth: '100%',
                  width: {
                    xs: '33px',
                    sm: '43px',
                    md: '52px',
                    lg: '80px',
                    xl: '116px',
                  },
                  height: {
                    xs: '43px',
                    sm: '73px',
                    md: '82px',
                    lg: '100px',
                    xl: '150px',
                  },
                  fontSize: {
                    xs: '14px',
                    sm: '18px',
                    md: '18px',
                    lg: '26px',
                    xl: '33px',
                  },
                  color:
                    isLight(theme.palette.primary.main) &&
                    isLight(theme.palette.primary.contrastText)
                      ? 'common.black'
                      : 'primary.contrastText',
                  borderRadius: '12px',
                  lineHeight: '1.3',
                  fontFamily: theme.typography.roboto,
                  marginBottom: '15px',
                  '&:hover': {
                    backgroundColor: alpha(
                      theme.palette.primary.main,
                      0.4
                    ),
                  },
                  '&.selected': {
                    '&:before': {
                      position: 'absolute',
                      content: "''",
                      width: '8px',
                      height: '8px',
                      backgroundColor: ifMainColorIsLight(theme),
                      borderRadius: '50%',
                      bottom: '-15px',
                    },
                  },
                  '&.MuiButton-outlined': {
                    backgroundColor: theme.palette.common.white,
                    color: isLight(theme.palette.primary.main)
                      ? isLight(theme.palette.primary.main) &&
                        isLight(theme.palette.primary.contrastText)
                        ? 'common.black'
                        : 'primary.contrastText'
                      : 'primary.main',
                  },
                }}
              >
                <span>{x.name[0]}</span>
                <span>{x.day}</span>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>

      {breakPointSM ? (
        <>
          <ScheduleMobile
            allTodayVideos={extractedVideos}
            activeDay={activeDay}
            loader={mobileLoader}
            daysArray={daysArray}
            parentCallback={handleCallbackMobileData}
          />
        </>
      ) : (
        <Box
          maxWidth={null}
          sx={{
            px: { xs: '27px', md: '50px' },
            marginLeft: 'unset',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  mt: { md: '6em', xs: '3em' },
                }}
              >
                <Typography
                  component="p"
                  variant="h1"
                  sx={{
                    fontSize: '1.3em',
                    letterSpacing: ' 0.15px',
                    marginBottom: '1em',
                  }}
                >
                  {daysArray.find((x) => x.day === activeDay).full}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Paper
            sx={{
              maxWidth: '100%',
              width: '100%',
              display: 'flex',
              overflowX: 'auto',
              pb: '10px',
              scrollBehavior: 'smooth',
              background: 'transparent',
            }}
            elevation={0}
          >
            <Grid
              container
              spacing={3}
              mt={0}
              sx={{ flexWrap: 'nowrap' }}
            >
              {extractedVideos.length === 0 ? (
                <>
                  {!extractedVideosLoader ? (
                    <Grid item xs={12}>
                      No Scheduled Videos
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <CircularProgress />
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {extractedVideos.map((vid, i) => {
                    return (
                      <Grid
                        key={i}
                        item
                        xs={12}
                        sm={8}
                        md={7}
                        lg={5}
                        sx={{
                          flex: '1 0 100%!important',
                          maxWidth: { xl: '593px!important' },
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                          }}
                        >
                          <VideoItem
                            vid={vid}
                            pageType={'schedule'}
                            // isRecent={
                            //   breakPointSM && vid.watched === true
                            //     ? true
                            //     : false
                            // }
                            // progress={handleProgressBar(vid)}
                          />
                          <Tooltip
                            title="Remove Video"
                            sx={{
                              position: 'absolute',
                              top: '14px',
                              right: '14px',
                              zIndex: '2',
                              backgroundColor:
                                theme.palette.common.white,

                              borderWidth: '1px',
                              borderStyle: 'solid',
                              borderColor: theme.palette.warning.main,
                              '&:hover': {
                                opacity: '0.8',
                              },
                            }}
                          >
                            <IconButton
                              aria-label="remove video"
                              variant="contained"
                              color={'error'}
                              size="small"
                              onClick={() => {
                                setDeleteScheduledVideo({
                                  scheduled_id: vid.scheduled_id,
                                  video_title: vid.title,
                                  hour: vid.scheduled,
                                  scheduled_date: daysArray.find(
                                    (x) => x.day === activeDay
                                  ).full,
                                });
                              }}
                            >
                              <CloseIcon
                                htmlColor={theme.palette.warning.main}
                                fontSize="small"
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </Paper>
          {/* Upcoming */}

          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  mt: { md: '6em', xs: '3em' },
                }}
              >
                <Typography
                  component="p"
                  variant="h1"
                  sx={{
                    fontSize: '1.8em',
                    letterSpacing: ' 0.15px',
                    color: ifMainColorIsLight(theme),
                    marginBottom: '1em',
                  }}
                >
                  Upcoming
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {upcomingVideos.length === 0 ? (
                <>
                  {!upcomingVideosLoader ? (
                    <>No Upcoming Scheduled Videos</>
                  ) : (
                    <CircularProgress />
                  )}
                </>
              ) : (
                <>
                  {upcomingVideos.map((x, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Typography
                          component="p"
                          variant="h1"
                          sx={{
                            fontSize: '1.3em',
                            letterSpacing: ' 0.15px',
                            marginBottom: '1em',
                            mt: { md: '2em', xs: '1em' },
                          }}
                        >
                          {moment(x.date, 'DD-MM-YYYY').format(
                            'dddd, MMMM Do YYYY'
                          )}
                        </Typography>
                        <Paper
                          sx={{
                            maxWidth: '100%',
                            width: '100%',
                            display: 'flex',
                            overflowX: 'auto',
                            pb: '10px',
                            scrollBehavior: 'smooth',
                            background: 'transparent',
                          }}
                          elevation={0}
                        >
                          <Grid
                            container
                            spacing={3}
                            mt={0}
                            sx={{ flexWrap: 'nowrap' }}
                          >
                            {x.videos.map((vid, i) => {
                              return (
                                <Grid
                                  key={i}
                                  item
                                  xs={12}
                                  sm={8}
                                  md={7}
                                  lg={5}
                                  sx={{
                                    flex: '1 0 100%!important',
                                    maxWidth: {
                                      xl: '593px!important',
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: '100%',
                                      height: '100%',
                                      position: 'relative',
                                    }}
                                  >
                                    <VideoItem
                                      vid={vid}
                                      pageType={'schedule'}
                                    />
                                    <Tooltip
                                      title="Remove Video"
                                      sx={{
                                        position: 'absolute',
                                        top: '14px',
                                        right: '14px',
                                        zIndex: '2',
                                        backgroundColor:
                                          theme.palette.common.white,

                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        borderColor:
                                          theme.palette.warning.main,
                                        '&:hover': {
                                          opacity: '0.8',
                                        },
                                      }}
                                    >
                                      <IconButton
                                        aria-label="remove video"
                                        variant="contained"
                                        color={'error'}
                                        size="small"
                                        onClick={() => {
                                          setDeleteScheduledVideo({
                                            scheduled_id:
                                              vid.scheduled_id,
                                            video_title: vid.title,
                                            hour: vid.scheduled,
                                            scheduled_date: moment(
                                              x.date,
                                              'DD-MM-YYYY'
                                            ).format(
                                              'dddd, MMMM Do YYYY'
                                            ),
                                          });
                                        }}
                                      >
                                        <CloseIcon
                                          htmlColor={
                                            theme.palette.warning.main
                                          }
                                          fontSize="small"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Paper>
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      <ConfirmDialog dialogData={dialogConfirmData} />
    </>
  );
}

export default Schedule;
