import React, { useContext } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  Box,
  Slide,
  Grid,
} from '@mui/material';
import AuthContext from '../context/auth/AuthContext';
import PageContext from '../context/page/PageContext';
import { useMatch, useResolvedPath } from 'react-router-dom';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { ReactComponent as WeightIcon } from './../assets/icons/weight.svg';
import { ReactComponent as CupIcon } from './../assets/icons/cup.svg';
import { ReactComponent as CalendarIcon } from './../assets/icons/calendar.svg';
import { ReactComponent as ProfileIcon } from './../assets/icons/user.svg';

import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material';

function Sidebar() {
  const { user } = useContext(AuthContext);
  const { page } = useContext(PageContext);
  const theme = useTheme();

  function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      threshold: 0,
      target: window ? window() : undefined,
    });
    //console.log(page['colors'][0]);
    const firstMainColor = page.primary_color;

    return (
      <Slide
        appear={false}
        direction="up"
        in={!trigger}
        sx={{
          backgroundColor: 'primary.main',
          transform: { md: 'translate(0px)!important' },
          visibility: { md: 'visible!important' },
          position: { md: 'inherit!important' },
          height: { xs: 'auto', md: '100%' },
          position: { md: 'inherit!important' },
          bottom: { xs: '0px', md: 'initial' },
          top: { xs: 'initial', md: 'initial' },
          pt: { xs: '29px', md: '31px' },
          pb: { xs: '17px', md: '40px' },
          borderRadius: { xs: '20px 20px 0px 0px', md: '0px' },
        }}
      >
        {children}
      </Slide>
    );
  }

  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

  const fontStyle = {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: '400',
    border: '0px',
    fontSize: {
      xs: 'min(max(12px, 3vw), 1.07rem)',
      md: '1.07rem',
    },
    textTransform: 'none',
  };

  function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
      <Button
        to={to}
        {...props}
        // variant={match ? 'contained' : 'outlined'}
        // variant='contained'

        sx={[
          match ? itemStyleActive : itemStyleInactive,
          {
            ...fontStyle,
            mt: '1px!important',
            py: 0,
            '&:hover': {
              ...itemStyleActive,
              border: '0px',
            },
            // '&:hover .iconMenu': {
            // 	background: {
            // 		xs: 'transparent',
            // 		sm: 'rgb(255,255,255, 0.4)',
            // 	},
            // 	borderRadius: '20px!important',
            // 	color: { xs: 'primary', sm: '#fff' },
            // },
            '& .iconMenu': {
              borderRadius: {
                xs: '25%',
                md: '20px!important',
              },
              padding: { xs: '7px', md: '17px' },
            },
            '& .iconMenu svg': {
              width: { xs: '7vw', sm: '30px', md: '40px' },
              height: { xs: '7vw', sm: '30px', md: '40px' },
              minWidth: { xs: '24px', md: '40px' },
              minHeight: { xs: '24px', md: '40px' },
              maxWidth: { xs: '40px', md: '40px' },
              maxHeight: { xs: '40px', md: '40px' },
            },
            '& .menuIconText': {
              transition: 'all 0.2s ease-in',
              display: 'inline-block',
            },
          },
        ]}
      >
        {children}
      </Button>
    );
  }
  const itemStyleInactive = {
    color: alpha(theme.palette.primary.contrastText, 0.4),
    '& .menuIconText': {
      my: { xs: '0px', md: '0px' },
      opacity: { xs: '1', md: '0' },
      maxHeight: { xs: 'auto', md: '0px' },
    },
    '& svg': {
      color: theme.palette.primary.contrastText,
      fill: theme.palette.primary.contrastText,
      opacity: 0.4,
    },
    '&:hover svg': {
      color: theme.palette.primary.contrastText,
      fill: theme.palette.primary.contrastText,
      opacity: 0.5,
    },
  };
  const itemStyleActive = {
    color: 'primary.contrastText',
    '& .iconMenu': {
      backgroundColor: alpha(theme.palette.primary.contrastText, 0.4),

      //'rgb(255,255,255, 0.4)',
    },
    '& .menuIconText': {
      opacity: { md: '1' },
      maxHeight: { md: '50px' },
      my: { md: '9px' },
    },
    '& svg': {
      color: theme.palette.primary.contrastText,
      fill: theme.palette.primary.contrastText,
      opacity: 0.5,
    },
  };

  if (!user) {
    return false;
  }
  return (
    <Grid
      item
      sx={{
        maxWidth: { xs: '100%', md: '120px' },
        order: { xs: '2', md: '1' },
        width: '100%',
      }}
    >
      <HideOnScroll>
        <AppBar
          sx={{
            width: '100%',
          }}
        >
          {page.favicon && page.favicon['512'] && (
            <Stack
              direction="column"
              alignItems="center"
              sx={{
                width: '100%',
                display: { xs: 'none', md: 'flex' },
                padding: '0px 15px',
                textDecoration: 'none',
              }}
            >
              <Box
                sx={{
                  backgroundColor: alpha(
                    theme.palette.primary.contrastText,
                    0.2
                  ),
                  borderRadius: '20px!important',
                  padding: { xs: '5px', md: '12px' },
                }}
              >
                <Button
                  component={Link}
                  to="/"
                  sx={{
                    backgroundImage: `url("${page.favicon['512']}")`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    width: '49px',
                    height: '49px',
                    minWidth: 'auto',
                  }}
                />
              </Box>
            </Stack>
          )}
          <Box
            sx={{
              width: '100%',
              height: '8vh',
              minHeight: '2rem',
              maxHeight: '74px',
              display: { xs: 'none', md: 'block' },
            }}
          ></Box>
          <Stack
            direction={{ xs: 'row', md: 'column' }}
            alignItems="center"
            sx={{
              width: '100%',
            }}
            spacing={0}
          >
            <ButtonGroup
              aria-label="text button group"
              orientation="vertical"
              sx={{
                flexDirection: { xs: 'row', md: 'column' },
                justifyContent: { xs: 'space-evenly', md: 'initial' },
                width: '100%',
              }}
            >
              {page.favicon && page.favicon['512'] && (
                <Box
                  component={RouterLink}
                  to="/"
                  sx={{
                    display: { xs: 'flex', md: 'none!important' },
                    padding: '0px 15px',
                    textDecoration: 'none',
                  }}
                >
                  <Stack alignItems="center" justifyContent="center">
                    <IconButton
                      aria-label=""
                      className="iconMenu"
                      color={'inherit'}
                      sx={{
                        padding: '7px',
                        backgroundColor: alpha(
                          theme.palette.primary.contrastText,
                          0.2
                        ),
                        borderRadius: {
                          xs: '25%',
                          md: '20px!important',
                        },
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          backgroundImage: `url("${page.favicon['512']}")`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center center',
                          backgroundRepeat: 'no-repeat',
                          width: {
                            xs: 'calc(7vw + 14px)',
                            sm: '44px',
                            md: '40px',
                          },
                          height: {
                            xs: 'calc(7vw + 14px)',
                            sm: '44px',
                            md: '40px',
                          },
                          minWidth: {
                            xs: 'calc(24px + 14px)',
                            md: '40px',
                          },
                          minHeight: {
                            xs: 'calc(24px + 14px)',
                            md: '40px',
                          },
                          maxWidth: {
                            xs: 'calc(50px + 14px)',
                            md: '40px',
                          },
                          maxHeight: {
                            xs: 'calc(50px + 14px)',
                            md: '40px',
                          },
                        }}
                      />
                    </IconButton>
                  </Stack>
                </Box>
              )}
              <CustomLink component={RouterLink} to="/">
                <Stack alignItems="center">
                  <IconButton
                    aria-label="workout"
                    className="iconMenu"
                    color={'inherit'}
                  >
                    <SvgIcon
                      component={WeightIcon}
                      inheritViewBox
                      htmlColor="inherit"
                    />
                  </IconButton>
                  <Typography
                    component="span"
                    className="menuIconText"
                  >
                    Workout
                  </Typography>
                </Stack>
              </CustomLink>

              <CustomLink component={RouterLink} to="/schedule">
                <Stack direction="column" alignItems="center">
                  <IconButton
                    aria-label="cup"
                    className="iconMenu"
                    color={'inherit'}
                  >
                    <SvgIcon
                      component={CalendarIcon}
                      inheritViewBox
                      htmlColor="inherit"
                    />
                  </IconButton>
                  <Typography
                    component="span"
                    className="menuIconText"
                  >
                    Schedule
                  </Typography>
                </Stack>
              </CustomLink>

              {/* <CustomLink component={RouterLink} to='/foryou'>
								<Stack direction='column' alignItems='center'>
									<IconButton
										aria-label='schedule'
										className='iconMenu'
										color={'inherit'}
									>
										<SvgIcon
											component={CupIcon}
											inheritViewBox
											htmlColor='inherit'
										/>
									</IconButton>
									<Typography
										component='span'
										className='menuIconText'
									>
										For You
									</Typography>
								</Stack>
							</CustomLink> */}

              {/* <CustomLink component={RouterLink} to="/profile">
                <Stack direction="column" alignItems="center">
                  <IconButton
                    aria-label="profile"
                    className="iconMenu"
                    color={'inherit'}
                  >
                    <SvgIcon
                      component={ProfileIcon}
                      inheritViewBox
                      htmlColor="inherit"
                    />
                  </IconButton>
                  <Typography
                    component="span"
                    className="menuIconText"
                  >
                    Profile
                  </Typography>
                </Stack>
              </CustomLink> */}
            </ButtonGroup>
          </Stack>
        </AppBar>
      </HideOnScroll>
    </Grid>
  );
}

export default Sidebar;
