import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import {
  Link,
  Link as RouterLink,
  useParams,
} from 'react-router-dom';

import AuthContext from '../../../context/auth/AuthContext';
import WorkoutVideoPortrait from './components/portrait/WorkoutVideoPortrait';
import WorkoutVideoLandscape from './components/landscape/WorkoutVideoLandscape';
import {toast} from "react-toastify";

const identifier = window.location.hostname;

const CategoryPlayVideos = () => {
  const params = useParams();
  const [category, setCategory] = useState(null);
  const [video, setVideo] = useState(null);
  const [allTags, setAllTags] = useState(null);
  const authContext = useContext(AuthContext);
  const [allVideos, setAllVideos] = useState([]);

  const [orientationLandscape, setOrientationLandscape] =
    useState(false);
  const { apiUrl, token } = authContext;
  const [totalVideos, setTotalVideos] = useState();

  const getCategory = async (id) => {
    return await axios.get(
      `${apiUrl}/v1/product/categories/${id}?domain=${identifier}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      if(err.response.status === 401){
        authContext.logout();
        toast.error('Session expired, please login again');
      }
    });
  };
  //get the category
  useEffect(() => {
    if (!params.cat_id || category) return;
    //get the category
    getCategory(params.cat_id).then((res) => {
      setCategory(res?.data?.data?.category[0]);
    });
  });

  //get the video to show based on vid_id param
  useEffect(() => {
    if (!category) return;
    let allVideosArray = getVideos(category);

    setAllVideos(allVideosArray);

    //set the first video from array
    setVideo(allVideosArray[0]);
  }, [category]);

  const getVideos = (cat) => {
    let videos = [];
    const traverse = (subcategory) => {
      ifKeyData(subcategory);
    };
    const ifKeyData = (subcategory) => {
      if (subcategory.videos) {
        videos = videos.concat(subcategory.videos);
      }
      if (subcategory.subcategories) {
        subcategory.subcategories.forEach(traverse);
      }
    };
    ifKeyData(cat);
    return videos;
  };


  // const getVideos = (category) => {
  //   let videos = [];
  
  //   const traverse = (subcategory) => {
     
  //     if (subcategory.videos) {
  //       videos = videos.concat(subcategory.videos);
  //     }
  //     if (subcategory.subcategories) {
  //       subcategory.subcategories.forEach((item)=>traverse(item));
  //     }

  //     if(!subcategory.videos && !subcategory.subcategories){
  //       if (Array.isArray(category)) {
  //         subcategory.forEach((item)=>traverse(item));
  //       }
  //     }
  //   };
    

  //   traverse(category)
  
  //   return videos;
  // };

  const filterAllVideos = (id) => {
    const foundVideos = allVideos.filter((video) => video.id === id);
    return foundVideos[0];
  };

  useEffect(() => {
    if (!category) {
      return;
    }

    //set the total videos number to show
    if (allVideos.length === 0 || allVideos.length > 1) {
      setTotalVideos(allVideos.length);
    } else if (allVideos.length === 1) {
      setTotalVideos(allVideos.length);
    }
  }, [allVideos]);


  // get current video tags
  useEffect(() => {
    let uniqueTagsArray = [];

    if (video && 'tags' in video && video.tags.length > 0) {
      video.tags.forEach((tag) => {
        uniqueTagsArray.push(tag);
      });
      setAllTags(uniqueTagsArray);
    }
  }, [video]);

  //**** keeping this here in order to have it if we need it , it will show ALL tags from all videos   
  // useEffect(() => {
  
  //   let tagsArray = [];
  //   if(allVideos.length > 0 ){
  //     allVideos.forEach((videoItem) =>{
  //       if (videoItem && 'tags' in videoItem && videoItem.tags.length > 0) {
  //         videoItem.tags.forEach((tag) => {
  //          tagsArray.push(tag);
  //         });
        
  //       }
  //     })
  //   }
  //   const uniqueTagsArray = [...new Set(tagsArray)];

  //   setAllTags(uniqueTagsArray);

  // }, [video]);
  

  useEffect(() => {
    [
      'fullscreenchange',
      'webkitfullscreenchange',
      'mozfullscreenchange',
      'msfullscreenchange',
    ].forEach((eventType) =>
      document.addEventListener(eventType, (event) => {
        if (
          orientationLandscape &&
          !(
            (document.fullscreenElement &&
              document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement &&
              document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement &&
              document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement &&
              document.msFullscreenElement !== null)
          )
        ) {
          setOrientationLandscape(false);
        }
      })
    );
  });

  // get the watched videos from child(videoItem)
  const handleCallbackVideoData = async (childData) => {
    if (childData) {
      if ('orientationLandscape' in childData) {
        setOrientationLandscape(childData.orientationLandscape);
      }
      if ('currentVideo' in childData) {
        setVideo(childData.currentVideo);
      }
    }
  };

  return (
    <>
      {!video ? (
        !category ? (
          <Stack
            direction="column"
            sx={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Stack
            direction="column"
            sx={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              width: '100%',
            }}
          >
            No videos found in this workout
          </Stack>
        )
      ) : (
        <>
          {!orientationLandscape ? (
            <WorkoutVideoPortrait
              category={category}
              videoPlaying={video}
              allTags={allTags}
              orientationLandscape={orientationLandscape}
              totalVideos={totalVideos}
              allVideos={allVideos}
              parentCallback={handleCallbackVideoData}
            />
          ) : (
            <WorkoutVideoLandscape
              category={category}
              videoPlaying={video}
              allTags={allTags}
              orientationLandscape={orientationLandscape}
              totalVideos={totalVideos}
              allVideos={allVideos}
              parentCallback={handleCallbackVideoData}
            />
          )}
        </>
      )}
    </>
  );
};

export default CategoryPlayVideos;
