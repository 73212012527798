import React from 'react';
import {
  Link,
  Link as RouterLink,
  useParams,
} from 'react-router-dom';

import moment from 'moment';
import ReactPlayer from 'react-player';
import CircularSlider from '@fseehawer/react-circular-slider';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { ifMainColorIsLight } from '../../../../../helpers/functions';
import ReplayIcon from '@mui/icons-material/Replay';

import { alpha, Box } from '@mui/system';
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { useRef } from 'react';
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

//material drawer
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

//icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import StartIcon from '@mui/icons-material/Start';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import Subcategory from './Subcategory';
//ct icons
import { ReactComponent as ArrowLeft } from '../../../../../assets/icons/arrow-left.svg';

//Schedule & Save
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { toast } from 'react-toastify';
import axios from 'axios';
import AuthContext from '../../../../../context/auth/AuthContext';
import { useContext } from 'react';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import {
  ScreenOrientation,
  OrientationType,
} from '@capawesome/capacitor-screen-orientation';

const drawerWidth = 500;
const drawerClosedWidth = '80px';
const openedMixin = (theme) => ({
  width: drawerWidth,
  [theme.breakpoints.down('sm')]: {
    width: 300,
  },
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  border: 0,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: drawerClosedWidth,
  [theme.breakpoints.up('sm')]: {
    width: drawerClosedWidth,
  },
  border: 0,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 20px 8px 20px',
  flexShrink: 0,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  // width: '100%',
  // maxWidth: drawerWidth,
  border: 0,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  justifyContent: 'center',
  '& .MuiPaper-root': {
    background: theme.palette.drawerBg.main,
    color: '#fff',
    padding: '12px 0px',
  },
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const WorkoutVideoPortrait = ({
  category,
  videoPlaying,
  allTags,
  orientationLandscape,
  totalVideos,
  allVideos,
  parentCallback,
}) => {
  const params = useParams();

  const theme = useTheme();
  const [touch, setTouch] = useState();
  const identifier = window.location.hostname;
  const [nextCategory, setNextCategory] = useState(false);

  //reactPlayer
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState();
  const [isReady, setIsReady] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);
  const playerRef = useRef();
  const [open, setOpen] = useState(false);
  const [circularWidth, setCircularWidth] = useState(false);
  const [knobSize, setKnobSize] = useState(false);
  //detect sm breakPoint
  const breakPointMD = useMediaQuery(theme.breakpoints.down('md'));
  //saveVideo
  const [savedVideo, setSavedVideo] = useState(false);

  //Schedule
  const [dialogConfirmData, setDialogConfirmData] = useState([]);
  const [scheduledVideo, setScheduleVideo] = useState([]);
  const [value, setValue] = React.useState(
    dayjs().format('YYYY-MM-DD HH:mm:ss')
  );
  const authContext = useContext(AuthContext);
  const { apiUrl, token } = authContext;

  //save the state of the video that is splaying
  const [videoState, setVideoState] = useState({
    id: false,
    progress: 0,
    timestamp: 0,
  });

  //init material drawer

  const [openedDrawer, setOpenedDrawer] = useState(false);

  const handleDrawerClose = () => {
    setOpenedDrawer(openedDrawer ? false : true);
  };

  //end of drawer

  // keep video seek state at it was leaved
  const onReady = React.useCallback(() => {
    //get current video id
    const videoID = playerRef.current.props.dataid;

    //find if this video is in watchedVideos
    findStoredVideo(videoID);

    setIsReady(true);
  }, [isReady]);

  const handleClickOpen = () => {
    if (!open && isReady) {
      if (Math.ceil(videoState.progress) >= videoState.progressEnd) {
        // setProgress(0);
        // setVideoState({ ...videoState, progress: 0 });
        playerRef.current.seekTo(0, 'seconds');
        // setOpen(true);
      }
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  //search the id in stored videos
  const findStoredVideo = (videoID) => {
    //get all watched videos
    const allVideos = JSON.parse(
      localStorage.getItem('videosWatched')
    );
    if (!allVideos) return;
    let obj = allVideos.find((item) => item.id === videoID);
    if (obj && allVideos) {
      let time = 0;
      if (obj.progress) {
        if (obj.progress !== obj.progressEnd) {
          time = obj.progress;
        }
        setVideoState(obj);
        setProgress(handleProgress());

        //set seekbar to current progress from stored videos

        playerRef.current.seekTo(time, 'seconds');
      }
    }
  };

  //action on videostate changing
  useEffect(() => {
    if (videoState) {
      setProgress(handleProgress());

      if (Math.round(videoState.progress) >= videoState.progressEnd) {
        setOpen(false);
      }
    }

    if (videoState.id) {
      let localStorageVideos = [];

      if (
        localStorage.getItem('videosWatched') === 'undefined' ||
        localStorage.getItem('videosWatched') === null
      ) {
        //if somehow this gets undefined, reset localstorage item
        localStorage.removeItem('videosWatched');
      } else if (localStorage.getItem('videosWatched')) {
        //if the videos are in local storage

        //get videos from local storage
        localStorageVideos = JSON.parse(
          localStorage.getItem('videosWatched')
        );
      }

      if (localStorageVideos.length < 1) {
        //if local storage has no videos, add curent video
        localStorageVideos.push(videoState);
      } else {
        //here you set the maximum videos that should be stored
        if (localStorageVideos.length > 5) {
          localStorageVideos.shift(); //removes the first video
        }

        localStorageVideos = renderDuplicates(
          localStorageVideos,
          videoState
        );
      }

      //set local storage videos
      localStorage.setItem(
        'videosWatched',
        JSON.stringify(localStorageVideos)
      );
      //openFullscreen(document.documentElement);
      // window.screen.orientation.lock('landscape');
    }
  }, [videoState]);

  const renderDuplicates = (localStorageVideos) => {
    let storedVideos = localStorageVideos;

    //find if there is a video with the same id
    let obj = storedVideos.find((item) => item.id === videoState.id);
    let index = storedVideos.indexOf(obj);

    if (obj) {
      storedVideos[index] = videoState;
    } else {
      //if there is not video with same id, add it to array
      storedVideos.push(videoState);
    }
    //if local storage has videos , filter them to avoid adding dupplicates
    localStorageVideos = storedVideos;
    return localStorageVideos;
  };

  //get the category
  useEffect(() => {
    //reset data
    setProgress(0);

    setVideoState({
      id: false,
      progress: 0,
      timestamp: 0,
    });
    setOpen(false);
    setVideoUrl(false);
    setIsReady(false);
    //detect if device was touched
    window.addEventListener('touchstart', function () {
      setTouch(true);
    });

    //set timeout to avoid error in React
    if (videoPlaying && videoPlaying.vimeo_id) {
      setTimeout(() => {
        setVideoUrl(`https://www.vimeo.com/${videoPlaying.vimeo_id}`);
        //close drawe on video change, on mobile res
        breakPointMD && setOpenedDrawer(false);
      }, 50);
      setSelectedMainCategory(
        findMainCategoryId(category, videoPlaying.id)
      );
      setSelectedAllSubCat(
        getVideoParentCategories(category, videoPlaying.id)
      );
    }
  }, [videoPlaying]);

  //handle circular Slider seek - sync with video
  const handleChange = (value) => {
    if (value !== progress && isReady) {
      playerRef.current.seekTo(handleValue(value), 'seconds');
    }
    //
  };

  //convert vid secons to min&sec
  const convertSecondsInMinutes = (elSeconds) => {
    const date = new Date(elSeconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad(date.getUTCSeconds());

    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
  };
  function pad(string) {
    return ('0' + string).slice(-2);
  }

  //set the circular sizes based on resolution
  useEffect(() => {
    if (!breakPointMD) {
      setKnobSize(55);
      setCircularWidth(150);
    } else {
      setCircularWidth(120);
      setKnobSize(50);
    }
  }, [breakPointMD]);

  //handle the circular slider progress- transform seconds to procent
  const handleProgress = () => {
    let progress = 0;
    if (videoState.id) {
      if (videoState.progress > 0) {
        progress =
          (videoState.progress * 359) / videoState.progressEnd;
      }
      if (videoState.progress >= videoState.progressEnd) {
        progress = 359;
      }
    }
    return Math.round(progress);
  };

  //handle the circular slider value to show on label - transform procent to seconds
  const handleValue = (value) => {
    let videoValue;

    if (value) {
      videoValue = (videoState.progressEnd * value) / 359;
    }
    if (videoValue >= videoState.progressEnd) {
      videoValue = videoState.progressEnd;
    }

    return videoValue;
  };

  //lock &unlock orientation

  //lock &unlock orientation

  const lock = async () => {
    try {
      await ScreenOrientation.lock({
        type: OrientationType.LANDSCAPE,
      });
    } catch (error) {
      return false;
    }
  };

  const unlock = async () => {
    try {
      await ScreenOrientation.unlock();
    } catch (error) {
      return false;
    }
  };

  const btnStyle = {
    marginTop: '30px',
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: {
      xs: '1rem',
      sm: '1.5rem',
      md: '1.8rem',
      lg: '2.1rem',
    },
    padding: {
      xs: '24px 15px',
      md: '28px 15px',
      lg: '38px 15px',
      xl: '45px 15px',
    },
    lineHeight: '1.2em',
    letterSpacing: '0.24px',
    borderRadius: '50px',
    height: 'auto',
    textTransform: 'none',
    boxShadow: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    display: 'block',
    textAlign: 'center',
    maxWidth: '600px',
  };

  const useStyles = makeStyles((theme) => ({
    containerMobile: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      flexGrow: '1',
      height: '100%',
      '@media  (orientation: landscape)  and (max-height:610px)': {
        paddingTop: '30px',
      },
    },
  }));
  const classes = useStyles();

  const changeOrientation = () => {
    let isInFullScreen =
      (document.fullscreenElement &&
        document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement &&
        document.msFullscreenElement !== null);

    let docElm = document.documentElement;
    if (!isInFullScreen && !orientationLandscape) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      } else if (docElm.webkitEnterFullscreen) {
        docElm.webkitEnterFullscreen();
      }

      if (lock() && isReady) lock();
      if (parentCallback) {
        parentCallback({ orientationLandscape: true });
      }
    } else if (orientationLandscape) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }

      if (parentCallback) {
        parentCallback({ orientationLandscape: false });
      }
      if (unlock() && isReady) unlock();
    }
  };

  //schedule
  const dateTimePickerField = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDateTimePicker
          displayStaticWrapperAs="mobile"
          openTo="day"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          hideTabs={true}
          // renderInput={(params) => <TextField {...params} />}
          disablePast={true}
          showToolbar={true}
          views={['day', 'hours', 'minutes', 'seconds']}
          getClockLabelText={(currentView) => currentView}
          renderInput={(params) => {
            return (
              <TextField
                disabled={true}
                value={value}
                placeholder={value}
              />
            );
          }}
        />
      </LocalizationProvider>
    );
  };

  useEffect(() => {
    //if there is a video id
    if (savedVideo && savedVideo.video_id) {
      //api to save video
      axios
        .post(
          `${apiUrl}/v1/client/video-wishlist/save`,
          { 
              video_id: savedVideo.video_id,
              domain:identifier
           
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success(`Video "${savedVideo.video_title}" Saved!`);
        })
        .catch((err) => {

          if(err.response.status === 401){
            authContext.logout();
            toast.error('Session expired, please login again');
          }else{
            console.error(err);
            toast.error(err.message);
          }
        });
    }
  }, [apiUrl, savedVideo, token]);

  //fulscreen breakpoint for dialog
  const dialogFullScreen = useMediaQuery(
    theme.breakpoints.down('sm')
  );

  //when the resolution changes, update dialog fullscreen
  useEffect(() => {
    setDialogConfirmData({
      ...dialogConfirmData,
      fullscreen: dialogFullScreen,
    });
  }, [dialogFullScreen]);

  //if there is a video_id in state, show dialog
  useEffect(() => {
    if (scheduledVideo && scheduledVideo.video_id) {
      //after dialog confirmation => onDialogConfirm

      setDialogConfirmData({
        title: `Schedule Video: "${scheduledVideo.video_title}"`,
        message: dateTimePickerField,
        onSubmit: onScheduleDialogConfirm,
        openAfterSubmit: true,
        showCloseIcon: false,
        confirmButtonText: 'Schedule',
        fullscreen: dialogFullScreen,
        dataToSend: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
        onCancel: OnDialogCancel,
      });
    }
  }, [scheduledVideo]);

  //reset schedule dialog
  const OnDialogCancel = () => {
    setDialogConfirmData([]);
    setScheduleVideo([]);
    setValue(dayjs().format('YYYY-MM-DD HH:mm:ss'));
  };

  useEffect(() => {
    setDialogConfirmData({
      ...dialogConfirmData,
      dataToSend: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
    });
  }, [value]);

  const onScheduleDialogConfirm = (date) => {
    let isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
    dayjs.extend(isSameOrBefore);

    let utc = require('dayjs/plugin/utc');
    let timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const isBeforeToday = dayjs(date).isSameOrBefore(
      dayjs().format('YYYY-MM-DD HH:mm:ss'),
      'year,month,day,hour,minutes',
      'seconds'
    );
    const timeZone = dayjs.tz.guess();

    if (isBeforeToday) {
      toast.error('Please select a future date & time');

      return;
    }
    if (date.length === 0 || !date) {
      toast.error('Please select date & time');

      return;
    }
    //Schedule video API
    axios
      .post(
        `${apiUrl}/v1/client/video-schedule/schedule`,
        {
          video_id: scheduledVideo.video_id,
          scheduled_at: date,
          // timezone: timeZone,
          domain:identifier
           },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.data.message);
        //reset dialog in order to close
        OnDialogCancel();
      })
      .catch((err) => {
        if(err.response.status === 401){
          authContext.logout();
          toast.error('Session expired, please login again');
        }else {
          console.error(err.message);
          toast.error(err.message);
        }
      });
  };

  const getCategories = async () => {
    return await axios.get(
      `${apiUrl}/v1/product/categories?domain=${identifier}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  useEffect(() => {
    if (progress <= 357) {
      setNextCategory(false);
    }
    if (
      progress >= 357 &&
      parseInt(getVideoIndexAllVideos(videoPlaying.id)) + 1 ===
        allVideos.length
    ) {


      getCategories().then((res) => {
        const categories = res?.data?.data?.categories;
        if (categories.length > 1) {
          if (
            categories[
              getCategoryIndexInCategories(category.id, categories) +
                1
            ]
          ) {
            setNextCategory(
              categories[
                getCategoryIndexInCategories(
                  category.id,
                  categories
                ) + 1
              ].id
            );
          } else {
            setNextCategory(categories[0].id);
          }
        }
      });
    }
  }, [progress]);

  const getCategoryIndexInCategories = (id, categories) => {
    return categories.findIndex((category) => category.id === id);
  };

  const filterAllVideos = (id) => {
    return allVideos.filter((video) => video.id === id);
  };

  const getVideoIndexAllVideos = (id) => {
    return allVideos.findIndex((video) => video.id === id);
  };

  const getNextVideo = (id) => {
    let currentVideo = parseInt(getVideoIndexAllVideos(id));
    let nextVideo = allVideos[currentVideo + 1];

    return currentVideo + 1 !== allVideos.length ? nextVideo : false;
  };

  const getPrevVideo = (id) => {
    let currentVideo = parseInt(getVideoIndexAllVideos(id));
    let prevVideo = allVideos[currentVideo - 1];

    return currentVideo === 0 ? false : prevVideo;
  };

  const styles = (theme) => ({
    disabledButton: {
      fill: '#c4c4c4',
      color: '#c4c4c4',
    },
  });
  //css
  const chipStyle = {
    padding: { xs: '5.5px 15px', sm: '9px 30px' },
    fontSize: { xs: '12px', sm: '14px' },
    color: theme.palette.drawerBg.main,
    background: '#C4C4C4',
    textTransform: 'capitalize',
    fontWeight: 700,
    height: '32px',
    borderRadius: '2rem',
    whiteSpace: 'no-wrap',
    width: { xs: 'calc(50% - 15px)', sm: 'auto' },
    whiteSpace: 'nowrap',
  };

  const Item = styled('span')(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.white.main,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '16px',
    color: '#fff',
  }));

  const [selectedVideo, setSelectedVideo] = useState(null);

  const [selectedMainCategory, setSelectedMainCategory] =
    useState(null);
  const [selectedAllSubCat, setSelectedAllSubCat] = useState(null);
  const [subCatExpanded, setSubCatExpanded] = useState([]);

  const findMainCategoryId = (obj, selectedVideoId) => {
    let mainCategoryId;
    const findVideo = (subcategories, parentId) => {
      subcategories.forEach((subcategory) => {
        if (subcategory.videos) {
          subcategory.videos.forEach((video) => {
            if (video.id === selectedVideoId) {
              mainCategoryId = parentId;
            }
          });
        }
        if (subcategory.subcategories) {
          findVideo(subcategory.subcategories, parentId);
        }
      });
    };
    if (obj.subcategories) {
      obj.subcategories.forEach((subcategory) => {
        if (subcategory.subcategories) {
          findVideo(
            subcategory.subcategories,
            subcategory.id
          );
        }
        if (subcategory.videos) {
          subcategory.videos.forEach((video) => {
            if (video.id === selectedVideoId) {
              mainCategoryId = subcategory.id;
            }
          });
        }
      });
    }

    return mainCategoryId;
  };

  const getVideoParentCategories = (category, vidId) => {
    let allIds = [];
    let parentCatVideo;

    const getIds = (cat, prevParent) => {
      let newParent = getParentSubcategory(category, prevParent);
      if (!allIds.includes(newParent) && newParent) {
        allIds.push(newParent);

        getIds(category, newParent);
      }
    };
    if (vidId && getVideoParentSubcategory(category, vidId)) {
      parentCatVideo = getVideoParentSubcategory(category, vidId);
      allIds.push(parentCatVideo);
    }
    if (parentCatVideo) {
      getIds(category, parentCatVideo);
    }

    return allIds;
  };

  //get the main category of the selected category
  //saving not knowing if I need it anymore

  // const getCatMainCategory = (data, categoryId) => {
  //   let parentCategory = null;
  //   const search = (obj, subcat) => {
  //     if (getParentSubcategory(data, subcat)) {
  //       search(data, getParentSubcategory(data, subcat));
  //     } else {
  //       parentCategory = subcat;
  //       return false;
  //     }
  //   };
  //   if (getParentSubcategory(data, categoryId)) {
  //     search(data, getParentSubcategory(data, categoryId));
  //   } else {
  //     return categoryId;
  //   }
  //   return parentCategory;
  // };

  const colorSelected = (id) => {
    return selectedAllSubCat &&
      selectedAllSubCat.length > 0 &&
      selectedAllSubCat.includes(id)
      ? theme.palette.primary.contrastText
      : '#c4c4c4';
  };
  const backgroundSelected = (
    id,
    nestedScale,
    index,
    arrayExpanded,
    list
  ) => {
    return selectedAllSubCat &&
      selectedAllSubCat.length > 0 &&
      selectedAllSubCat.includes(id)
      ? theme.palette.primary.main
      : arrayExpanded && arrayExpanded.includes(list)
      ? alpha('#6a6a6a', 0.2)
      : '';
  };
  //+ nestedScale / 10
  // /, 0.2 + nestedScale / 2 / 10
  //get the parent subcategory id of the video
  const getVideoParentSubcategory = (category, videoId) => {
    let parentSubcategory = null;
    const search = (obj) => {
      if (obj.subcategories) {
        obj.subcategories.forEach((sub) => {
          if (sub.videos) {
            sub.videos.forEach((video) => {
              if (video.id === videoId) {
                parentSubcategory = sub.id;
                return true;
              } else {
                search(sub);
              }
            });
          }
          if (obj.subcategories) {
            search(sub);
          }
        });
      }
    };
    search(category);
    return parentSubcategory;
  };

  //get the parent subcategory id of the selected subcategory
  function findParentCategory(data, video) {
    if (data.videos && data.videos.includes(video)) {
      return data.id;
    } else if (data.subcategories) {
      for (let subcategory of data.subcategories) {
        let parentCategory = findParentCategory(subcategory, video);
        if (parentCategory) {
          return parentCategory;
        }
      }
    }
    return null;
  }

  //get the parent Subcategory of the selected category
  const getParentSubcategory = (obj, subcategoryId) => {
    let parentSubcategory = null;
    const search = (obj) => {
      if (obj.subcategories) {
        obj.subcategories.forEach((sub) => {
          if (sub.id === subcategoryId) {
            parentSubcategory = obj.id;
            return true;
          } else {
            search(sub);
          }
        });
      }
    };
    search(obj);
    return parentSubcategory;
  };

  //send current video to parent CategoryPlayVideos
  const handleSubcategoryData = (childData) => {
    if (childData) {
      if ('currentVideo' in childData) {
        parentCallback({ currentVideo: childData.currentVideo });
      }
      if ('savedVideo' in childData) {
        setSavedVideo(childData.savedVideo);
      }
      if ('scheduledVideo' in childData) {
        setScheduleVideo(childData.scheduledVideo);
      }
    }
  };
  return (
    <>
      <>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            paddingRight: { md: drawerClosedWidth, xl: 0 },
            minHeight: '100vh',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'stretch',
              flexGrow: 1,
            }}
          >
            {!videoPlaying || !videoUrl ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  minHeight: '100%',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box
                  className={classes.containerMobile}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'stretch',
                    p: {
                      xs: '20px 20px 40px 20px',
                      lg: '40px',
                      xl: '40px ',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      flexGrow: 1,
                      position: 'relative',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        right: '0px',
                        zIndex: '1',
                      }}
                    >
                      <Tooltip
                        title="Back to Workouts"
                        sx={{
                          width: 'auto',
                          height: 'auto',
                          background: 'rgba(255,255,255, 0.5)',
                          color: '#2D2D2D',
                          fontSize: '24px',
                          width: '40px',
                          height: '40px',
                          fontFamily: theme.typography.roboto,

                          '& svg': {
                            color: '#2D2D2D',
                            fill: '#2D2D2D',
                            width: '24px',
                            height: '24px',
                          },
                          '&:hover': {
                            backgroundColor: 'transparent',
                            color: '#2D2D2D',
                          },
                          '&:hover svg': {
                            color: '#2D2D2D',
                            fill: '#2D2D2D',
                          },
                        }}
                      >
                        <IconButton
                          aria-label="Back to Workouts"
                          variant="filled"
                          size="small"
                          component={RouterLink}
                          to={`/`}
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Tooltip>

                      <IconButton
                        variant="text"
                        onClick={handleDrawerClose}
                        sx={{
                          padding: 0,
                          display: { xs: 'inline-flex', md: 'none' },
                          background: 'rgba(255,255,255, 0.5)',
                          width: '40px',
                          height: '40px',

                          '& svg': {
                            fontSize: '35px',
                            width: '35px',
                            height: '35px',
                            fill: '#111',
                          },
                        }}
                      >
                        {openedDrawer ? (
                          <StartIcon htmlColor="#fff" />
                        ) : (
                          <MenuOpenIcon htmlColor="#fff" />
                        )}
                      </IconButton>
                    </Box>

                    <Box
                      className="ctContent"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'start',
                        width: '100%',
                        flexGrow: 1,
                        px: { xs: '0px', md: '20px', lg: '40px' },
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          display: 'flex',
                          width: '100%',
                          flexGrow: 1,

                          '& .videoR': {
                            height: !isReady
                              ? '0'
                              : { xs: 'auto', md: '100%' },
                            maxHeight: { xs: 'auto', md: '100%' },
                            opacity: !isReady ? '0!important' : '1',
                            width: !isReady ? '0!important' : '100%',
                          },
                          '& .videoR *': {
                            opacity: !isReady ? '0!important' : '1',
                            width: !isReady ? '0!important' : '100%',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            width: '100%',
                          }}
                        >
                          {!isReady ? (
                            <Stack
                              direction="row"
                              width="100%"
                              sx={{
                                height: { xs: 'auto', md: '100%' },
                                maxHeight: { xs: 'auto', md: '100%' },
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '150px',
                              }}
                            >
                              <CircularProgress />
                            </Stack>
                          ) : (
                            ''
                          )}
                          <ReactPlayer
                            className="videoR"
                            url={videoUrl}
                            controls={false}
                            width="100%"
                            height="100%"
                            pip={true}
                            stopOnUnmount={false}
                            playsinline={true}
                            playsInline={true}
                            config={{
                              vimeo: {
                                playerOptions: {
                                  playsinline: 1,
                                  controls: 0,
                                  fullscreen: 0,
                                  allowfullscreen: 0,
                                },
                              },
                            }}
                            onPause={() => setOpen(false)}
                            onPlay={() => setOpen(true)}
                            onProgress={(barprogress) => {
                              if (isReady && open) {
                                setVideoState({
                                  ...videoState,
                                  id: videoPlaying.id,
                                  progress: barprogress.playedSeconds,
                                  timestamp: moment().valueOf(),
                                });
                              }
                            }}
                            // onProgress={setPlayerState}
                            // onEnded={() => setPlaying(false)}
                            onDuration={(duration) => {
                              if (isReady) {
                                setDuration(duration);
                                setVideoState({
                                  ...videoState,
                                  progressEnd: duration,
                                });
                              }
                            }}
                            // ref={player}
                            ref={playerRef}
                            playing={open}
                            onReady={onReady}
                            dataid={videoPlaying.id}
                          />
                        </Box>
                      </Box>

                      {nextCategory ? (
                        <>
                          <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            sx={btnStyle}
                            component={RouterLink}
                            to={`/workout/${nextCategory}`}
                          >
                            Next Workout
                          </Button>
                        </>
                      ) : (
                        ''
                      )}

                      <Grid
                        container
                        className="underVideoTools"
                        direction="row"
                        spacing={'20px'}
                        sx={{
                          justifyContent: {
                            xs: 'center',
                            sm: 'space-between',
                          },
                          width: '100%',
                          marginTop: '34px',
                          // flexWrap:{xs:'wrap',md:'no-wrap'}
                        }}
                      >
                        <Grid
                          item
                          className="prevNextVideo"
                          xs={12}
                          sm={6}
                          md={5}
                          sx={{
                            display: 'flex',
                            alignItems: { xs: 'center', sm: 'start' },
                            flexDirection: 'column',
                            flexGrow: 1,
                            justifyContent: 'flex-end',
                            order: {
                              xs: '2',
                              md: '1',
                            },
                          }}
                        >
                          <Stack
                            direction="column"
                            sx={{
                              display: 'inline-flex',
                              flexGrow: 0,
                              flexShrink: 1,
                              alignItems: {
                                xs: 'center',
                                md: 'flex-start',
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              sx={{
                                alignItems: 'center',
                                flexGrow: 0,
                                flexShrink: 1,
                              }}
                            >
                              <IconButton
                                aria-label="prev video"
                                sx={{
                                  p: 0,
                                  color: theme.palette.primary.main,
                                }}
                                onClick={() => {
                                  if (getPrevVideo(videoPlaying.id))
                                    parentCallback({
                                      currentVideo: getPrevVideo(
                                        videoPlaying.id
                                      ),
                                    });
                                }}
                                classes={{
                                  disabled: classes.disabledButton,
                                }}
                                disabled={
                                  getPrevVideo(videoPlaying.id)
                                    ? false
                                    : true
                                }
                              >
                                <ArrowForwardIosIcon
                                  htmlColor="inherit"
                                  sx={{
                                    transform: 'rotate(180deg)',
                                    height: { xs: 38, md: 48 },
                                    width: { xs: 38, md: 44 },
                                  }}
                                />
                              </IconButton>

                              <Typography
                                color="primary"
                                sx={{
                                  fontSize: '48px',
                                  fontFamily: theme.typography.roboto,
                                  p: '0',
                                  textTransform: 'none',
                                  height: 'auto',
                                  fontWeight: 'normal',
                                  letterSpacing: '0.15px',
                                  lineHeight: '1.2em',
                                  color: '#c4c4c4',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <Typography
                                  component="span"
                                  sx={{
                                    color:
                                      theme.palette.drawerBg.main,
                                    fontSize: 'inherit',
                                    fontFamily: 'inherit',
                                  }}
                                >
                                  {getVideoIndexAllVideos(
                                    videoPlaying.id
                                  ) + 1}
                                </Typography>{' '}
                                / {totalVideos}
                              </Typography>

                              <IconButton
                                aria-label="next video"
                                onClick={() => {
                                  if (getNextVideo(videoPlaying.id))
                                    parentCallback({
                                      currentVideo: getNextVideo(
                                        videoPlaying.id
                                      ),
                                    });
                                }}
                                disabled={
                                  getNextVideo(videoPlaying.id)
                                    ? false
                                    : true
                                }
                                classes={{
                                  disabled: classes.disabledButton,
                                }}
                                sx={{
                                  p: 0,
                                  color: theme.palette.primary.main,
                                }}
                              >
                                <ArrowForwardIosIcon
                                  htmlColor="inherit"
                                  sx={{
                                    height: { xs: 38, md: 48 },
                                    width: { xs: 38, md: 44 },
                                  }}
                                />
                              </IconButton>
                            </Stack>

                            <Typography
                              className="videoTitle"
                              sx={{
                                fontFamily: theme.typography.roboto,
                                fontSize: '18px',
                                color: theme.palette.drawerBg.main,
                                marginRight: '15px',
                                marginLeft: '12px',
                                margin: 'auto',
                              }}
                            >
                              {videoPlaying.title}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          className="videoSlider"
                          xs={12}
                          md={2}
                          sx={{
                            alignItems: 'center',
                            flexDirection: 'column',
                            flexGrow: 1,
                            justifyContent: 'flex-end',
                            order: {
                              xs: '1',
                              md: '2',
                            },
                          }}
                        >
                          {isReady ? (
                            <Box
                              sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Box
                                className="circularSlider"
                                sx={{
                                  '&.circularSlider>div>div:first-of-type>svg':
                                    {
                                      p: '23%',
                                    },
                                  '&.circularSlider>div>div:first-of-type>svg>svg':
                                    {
                                      display: 'none',
                                    },
                                  '&.circularSlider>div>div:last-child>div:last-child':
                                    {
                                      display: 'none',
                                    },
                                  '&.circularSlider>div>div:last-child>div:first-of-type':
                                    {
                                      transition: 'all 0.5s ease-in',
                                      display: !open
                                        ? 'none'
                                        : 'block',
                                      fontSize: {
                                        xs: '24px!important',
                                        md: '2rem!important',
                                      },
                                      fontWeight: 'bold',
                                    },
                                }}
                              >
                                <CircularSlider
                                  dataIndex={progress}
                                  label={`${
                                    videoState.progress > 0
                                      ? convertSecondsInMinutes(
                                          videoState.progress
                                        )
                                      : convertSecondsInMinutes(
                                          duration
                                        )
                                  }`}
                                  labelFontSize={'24px'}
                                  valueFontSize={'0px'}
                                  labelColor={'darkBg.main'}
                                  knobColor={
                                    theme.palette.primary.main
                                  }
                                  progressColorFrom={
                                    theme.palette.primary.light
                                  }
                                  progressColorTo={
                                    theme.palette.primary.main
                                  }
                                  trackColor="#EAEAEA"
                                  trackSize={5}
                                  width={circularWidth}
                                  progressSize={5}
                                  knobSize={knobSize}
                                  onChange={handleChange}
                                  sx={{
                                    '& svg': { display: 'none' },
                                  }}
                                  min={0}
                                  max={359}
                                />
                              </Box>
                              <IconButton
                                onClick={handleClickOpen}
                                variant="text"
                                color="inherit"
                                sx={{
                                  position: 'absolute',
                                  width: { xs: '55px', md: '80px' },
                                  height: '50px',
                                  zIndex: '4',
                                  bordeRadius: '0',
                                  p: 0,
                                  '&:hover ': {
                                    background:
                                      open && !touch
                                        ? '#fff'
                                        : 'transparent',
                                  },
                                  '&:hover  .pauseIcon': {
                                    display: open ? 'block' : 'none',
                                  },
                                  '& .playIcon,& .replayIcon': {
                                    //  display: open ? 'none' : 'block',
                                    width: '50px',
                                    height: '50px',
                                    fontSize: '50px',
                                  },
                                  '& .pauseIcon': {
                                    display: touch
                                      ? 'none!important'
                                      : 'none',
                                    width: '50px',
                                    height: '50px',
                                    fontSize: '50px',
                                    transition: 'all 0.5s ease-in',
                                    fill: '#8d8d8d',
                                    color: '#8d8d8d',
                                  },
                                }}
                              >
                                {!open ? (
                                  Math.ceil(videoState.progress) >=
                                  videoState.progressEnd ? (
                                    <ReplayIcon className="replayIcon" />
                                  ) : (
                                    <PlayArrowIcon className="playIcon" />
                                  )
                                ) : !open && touch ? (
                                  ''
                                ) : (
                                  <PauseIcon className="pauseIcon" />
                                )}
                              </IconButton>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={circularWidth}
                                sx={{
                                  height: circularWidth,
                                }}
                              />
                            </Box>
                          )}
                        </Grid>
                        <Grid
                          item
                          className="videoActionButtons"
                          xs={12}
                          sm={6}
                          md={5}
                          sx={{
                            display: 'flex',
                            alignItems: {
                              xs: 'center',
                              sm: 'flex-end',
                            },
                            flexDirection: 'column',
                            flexGrow: 1,
                            justifyContent: 'flex-end',
                            order: '3',
                          }}
                        >
                          <Stack
                            direction="column"
                            align="center"
                            spacing="15px"
                            sx={{
                              justifyContent: 'center',
                              flexGrow: 1,
                            }}
                          >
                            <Button
                              size="small"
                              variant="text"
                              sx={{
                                alignItems: 'center',
                                justifyContent: {
                                  xs: 'center',
                                  md: 'flex-end',
                                },
                                display: 'flex',
                                padding: '0',
                                borderRadius: '15px',
                                borderWidth: '0px',
                                fontSize: '16px',
                                color: theme.palette.drawerBg.main,
                                textTransform: 'initial',
                                lineHeight: 'initial',
                                marginTop: '20px!important',
                                '&:hover ': {
                                  background: 'none',
                                },
                                '& svg': {
                                  color: ifMainColorIsLight(theme),
                                  fill: ifMainColorIsLight(theme),
                                  width: '37px',
                                  height: '37px',
                                },
                                '&:hover svg': {
                                  color: theme.palette.drawerBg.main,
                                  fill: theme.palette.drawerBg.main,
                                },
                              }}
                              onClick={() => {
                                setSavedVideo({
                                  video_id: videoPlaying.id,
                                  video_title: videoPlaying.title,
                                });
                              }}
                            >
                              <Stack direction="row" spacing={'8px'}>
                                <Typography
                                  component="span"
                                  sx={{
                                    lineHeight: 'initial',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  Save to watch later
                                </Typography>
                                <AddIcon
                                  htmlColor={ifMainColorIsLight(
                                    theme
                                  )}
                                  fontSize="large"
                                />
                              </Stack>
                            </Button>

                            <Button
                              size="small"
                              variant="text"
                              onClick={() => {
                                setScheduleVideo({
                                  video_id: videoPlaying.id,
                                  video_title: videoPlaying.title,
                                });
                              }}
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                padding: '0',
                                borderRadius: '15px',
                                borderWidth: '0px',
                                fontSize: '16px',
                                color: theme.palette.drawerBg.main,
                                textTransform: 'initial',
                                justifyContent: {
                                  xs: 'center',
                                  md: 'flex-end',
                                },
                                lineHeight: 'initial',
                                marginTop: '20px!important',
                                '&:hover ': {
                                  background: 'none',
                                },
                                '& svg': {
                                  color: ifMainColorIsLight(theme),
                                  fill: ifMainColorIsLight(theme),
                                  width: '33px',
                                  height: '33px',
                                },

                                '&:hover svg': {
                                  color: theme.palette.drawerBg.main,
                                  fill: theme.palette.drawerBg.main,
                                },
                              }}
                            >
                              <Stack direction="row" spacing={'8px'}>
                                <Typography
                                  component="span"
                                  sx={{
                                    lineHeight: 'initial',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  Schedule Video
                                </Typography>
                                <CalendarMonthIcon
                                  htmlColor={
                                    theme.palette.common.white
                                  }
                                  fontSize="large"
                                />
                              </Stack>
                            </Button>

                            <Button
                              size="small"
                              variant="text"
                              onClick={changeOrientation}
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                padding: '0',
                                borderRadius: '15px',
                                borderWidth: '0px',
                                fontSize: '16px',
                                color: theme.palette.drawerBg.main,
                                textTransform: 'initial',
                                justifyContent: {
                                  xs: 'center',
                                  md: 'flex-end',
                                },
                                lineHeight: 'initial',
                                marginTop: '20px!important',
                                '&:hover ': {
                                  background: 'none',
                                },
                                '& svg': {
                                  color: ifMainColorIsLight(theme),
                                  fill: ifMainColorIsLight(theme),
                                  width: '33px',
                                  height: '33px',
                                },

                                '&:hover svg': {
                                  color: theme.palette.drawerBg.main,
                                  fill: theme.palette.drawerBg.main,
                                },
                              }}
                            >
                              <Stack direction="row" spacing={'8px'}>
                                <Typography
                                  component="span"
                                  sx={{
                                    lineHeight: 'initial',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  Full-screen
                                </Typography>
                                <OpenInFullIcon
                                  htmlColor={
                                    theme.palette.common.white
                                  }
                                  fontSize="large"
                                />
                              </Stack>
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            <ConfirmDialog dialogData={dialogConfirmData} />
          </Box>

          <Drawer
            variant="permanent"
            anchor="right"
            open={openedDrawer}
            sx={{
              position: 'relative',
              [theme.breakpoints.down('xl')]: {
                position: 'fixed!important',
              },

              zIndex: '111',
              display: {
                xs: openedDrawer ? 'flex' : 'none',
                md: 'flex',
              },
            }}
          >
            <DrawerHeader className="drawerHeader">
              {openedDrawer ? (
                <Typography
                  color="#fff"
                  variant="h1"
                  sx={{
                    fontFamily: theme.typography.roboto,
                    fontSize: { xs: '30px', md: '48px' },
                    fontWeight: 'bold',
                    whiteSpace: 'break-spaces',
                    overflow: 'hidden',
                    maxWidth: '100%',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {category.title}
                </Typography>
              ) : (
                ''
              )}
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  padding: 0,
                  '& svg': {
                    fontSize: '35px',
                    width: '35px',
                    height: '35px',
                  },
                }}
              >
                {openedDrawer ? (
                  <StartIcon htmlColor="#fff" />
                ) : (
                  <MenuOpenIcon htmlColor="#fff" />
                )}
              </IconButton>
            </DrawerHeader>

            {openedDrawer && allTags && allTags.length > 0 ? (
              <>
                <Stack
                  direction="row"
                  spacing={'20px'}
                  sx={{
                    marginBottom: '20px',
                    padding: '0px 20px',
                    flexWrap: 'wrap',
                  }}
                >
                  {allTags.map((name, i) => (
                    <Chip
                      sx={chipStyle}
                      key={i}
                      className="tag-fl"
                      label={name}
                    />
                  ))}
                </Stack>

                <Stack
                  direction="row"
                  spacing={{ xs: '10px', md: '22px' }}
                  sx={{
                    padding: '0px 20px',
                    flexWrap: 'wrap',
                    marginBottom: '10px',
                    alignItems: 'center',
                  }}
                >
                  <Item
                    className="videos"
                    sx={{
                      marginBottom: '10px!important',
                      fontSize: {
                        xs: '14px',
                        md: '16px',
                      },
                      '& svg': {
                        fontSize: { xs: '15px', sm: '24px' },
                        width: { xs: '15px', sm: '24px' },
                        height: { xs: '15px', sm: '24px' },
                      },
                    }}
                  >
                    <PlayCircleOutlineIcon htmlColor="#c4c4c4" />
                    <Typography
                      component="span"
                      variant="p"
                      sx={{
                        fontWeight: 'bolder',
                        fontSize: 'inherit',
                      }}
                    >
                      {totalVideos}
                    </Typography>
                    <Typography
                      component="span"
                      variant="p"
                      sx={{
                        fontWeight: 'normal',
                        fontSize: 'inherit',
                      }}
                    >
                      {totalVideos > 1 ? 'videos' : 'video'}
                    </Typography>
                  </Item>
                  <Item
                    className="calories"
                    sx={{
                      marginBottom: '10px!important',
                      fontSize: {
                        xs: '14px',
                        md: '16px',
                      },
                      '& svg': {
                        fontSize: { xs: '15px', sm: '24px' },
                        width: { xs: '15px', sm: '24px' },
                        height: { xs: '15px', sm: '24px' },
                      },
                    }}
                  >
                    <FitnessCenterIcon htmlColor="#c4c4c4" />
                    <Typography
                      component="span"
                      variant="p"
                      sx={{
                        fontWeight: 'bolder',
                        fontSize: 'inherit',
                      }}
                    >
                      {category.total_kcal}
                    </Typography>
                    <Typography
                      component="span"
                      variant="p"
                      sx={{
                        fontWeight: 'normal',
                        fontSize: 'inherit',
                      }}
                    >
                      kcal
                    </Typography>
                  </Item>
                  <Item
                    className="time"
                    sx={{
                      marginBottom: '10px!important',

                      fontSize: {
                        xs: '14px',
                        md: '16px',
                      },
                      '& svg': {
                        fontSize: { xs: '15px', sm: '24px' },
                        width: { xs: '15px', sm: '24px' },
                        height: { xs: '15px', sm: '24px' },
                      },
                    }}
                  >
                    <ScheduleIcon htmlColor="#c4c4c4" sx={{}} />
                    <Typography
                      component="span"
                      variant="p"
                      sx={{
                        fontWeight: 'bolder',
                        fontSize: 'inherit',
                      }}
                    >
                      {Math.ceil(category.total_length/60)}
                    </Typography>
                    <Typography
                      component="span"
                      variant="p"
                      sx={{
                        fontWeight: 'normal',
                        fontSize: 'inherit',
                      }}
                    >
                      min
                    </Typography>
                  </Item>
                </Stack>
              </>
            ) : (
              ''
            )}

            <Stack
              direction={'column'}
              alignItems={'start'}
              justifyContent={'start'}
              sx={{ width: '100%' }}
            >
              {category && category.subcategories.length > 0 && (
                <Subcategory
                  category={category}
                  selectedVideo={selectedVideo}
                  openedDrawer={openedDrawer}
                  selectedMainCategory={selectedMainCategory}
                  subCatExpanded={subCatExpanded}
                  parentVideoCallback={handleSubcategoryData}
                  parentVideo={videoPlaying}
                  selectedAllSubCat={selectedAllSubCat}
                  colorSelected={colorSelected}
                  backgroundSelected={backgroundSelected}
                  nestedScale={1}
                />
              )}
            </Stack>
          </Drawer>
        </Box>
      </>
    </>
  );
};

export default WorkoutVideoPortrait;
