import styled from '@emotion/styled';
import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import VideoItem from '../../../components/videoitem/VideoItem';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';

const ScheduleMobile = ({
  allTodayVideos,
  parentCallback,
  activeDay,
  loader,
  daysArray,
}) => {
  const theme = useTheme();
  const [deleteScheduledVideo, setDeleteScheduledVideo] =
    useState(false);
  const [todaySelectedHour, setTodaySelectedHour] = useState(false);
  const [receivedVideos, setReceivedVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);

  const [recentWatchedVideos, setRecentWatchedVideos] =
    useState(false);
  const [title, setTitle] = useState(' ');

  const [loaderMobile, setLoaderMobile] = useState(true);

  const Item = styled('div')(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.white.main,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: '#000000',
    textTransform: 'lowercase',
    fontWeight: 'bold',
    fontFamily: theme.typography.roboto,
    fontSize: '13px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&.active': {
      color: theme.palette.primary.main,
    },
    '&.vidOldBold': {
      color: '#a7a5a5',
    },
    '&.vidOld': {
      color: '#ccc',
    },
  }));

  //add extra video data needed in layout to style
  const addVideoExtraData = (videos) => {
    let allFiltered = [];

    //loop trough  videos
    videos.forEach((video) => {
      //set default values
      video.watched = false;
      video.progress = 0;
      video.old = true;

      //set if the video is older than now date - for laout design
      if (
        moment(activeDay, 'D').format('D') === moment().format('D')
      ) {
        if (
          moment(video.scheduled, 'hh:mm A').format('HH:mm') >=
          moment().format('HH:mm')
        ) {
          video.old = false;
        } else {
          video.old = true;
        }
      } else if (
        moment(activeDay, 'D').format('D') > moment().format('D')
      ) {
        video.old = false;
      } else if (
        moment(activeDay, 'D').format('D') < moment().format('D')
      ) {
        video.old = true;
      }

      //search trough all stored recently watched videos
      if (recentWatchedVideos && recentWatchedVideos.length > 0) {
        //search for the video in recent watched
        recentWatchedVideos.forEach((el) => {
          if (video.id === el.id) {
            //set if it was watched and the progress
            video.watched = true;
            video.progress = el.progress;
            video.progressEnd = el.progressEnd;
          }
        });
      }
      //push video to array
      allFiltered.push(video);
    });

    return allFiltered;
  };

  //event when parent sends the video
  useEffect(() => {
    //set the loader according to parent
    setLoaderMobile(loader);

    //set the state of received videos
    setReceivedVideos(addVideoExtraData(allTodayVideos));

    //find if today there are upcoming vids
    let findsFutureVidsToday = allTodayVideos.find((vid, i) => {
      return (
        moment(vid.scheduled, 'hh:mm A').format('HH:mm') >=
        moment().format('HH:mm')
      );
    });

    //change the title according to when was the video schedule
    if (
      (moment(activeDay, 'D').format('D') === moment().format('D') &&
        findsFutureVidsToday) ||
      moment(activeDay, 'D').format('D') > moment().format('D')
    ) {
      setTitle('Upcoming');
    } else {
      setTitle(' ');
    }
  }, [allTodayVideos]);

  //event on receivedVideos state
  useEffect(() => {
    //if there are no videos , do nothing
    if (!receivedVideos || receivedVideos.length === 0) {
      return;
    }

    //set the video to start with when showing days scheduled videos , accordin with time scheduled . It will show upcoming video first, if there are upcoming videos
    if (
      getTheNextUpcoming() &&
      moment(activeDay, 'D').format('D') === moment().format('D')
    ) {
      setTodaySelectedHour({ id: getTheNextUpcoming() });
    } else {
      setTodaySelectedHour({ id: receivedVideos[0].scheduled_id });
    }
    //get the recentWatched vides from localstorage and set state
    let recentWatched = localStorage.getItem('videosWatched')
      ? JSON.parse(localStorage.getItem('videosWatched'))
      : false;
    setRecentWatchedVideos(recentWatched);

    //set filtered videos to show according to selected date
    setFilteredVideos(getTheFilteredVideos());
  }, [receivedVideos]);

  //event when user selects another date
  useEffect(() => {
    if (!receivedVideos || receivedVideos.length === 0) {
      return;
    }
    //set filtered videos to show according to selected date
    setFilteredVideos(getTheFilteredVideos());
  }, [todaySelectedHour]);

  //filter videos to show according to selected hour
  const getTheFilteredVideos = () => {
    //default assignment
    let showVideo = receivedVideos;

    //if there is a day selected , filter videos to show the one selected
    if (todaySelectedHour) {
      showVideo = receivedVideos.filter((vid, i) => {
        return vid.scheduled_id === todaySelectedHour.id;
      });
    }
    //add videos extra data needed in layout
    let videosFiltered = addVideoExtraData(showVideo);

    return videosFiltered;
  };

  //find if in todays videos there is an upcoming video
  const getTheNextUpcoming = () => {
    let nextUpcoming = false;
    let video = receivedVideos.find((vid, i) => {
      return (
        moment(vid.scheduled, 'hh:mm A').format('HH:mm') >=
        moment().format('HH:mm')
      );
    });
    //if yes set the scheduled id
    if (video) nextUpcoming = video.scheduled_id;
    return nextUpcoming;
  };

  // handle progress bar for watched videos
  const handleProgressBar = (video) => {
    let progress = video.progress;
    if (!video || !video.watched) {
      return 0;
    }
    if (video.progress > 0) {
      progress = (video.progress * 100) / video.progressEnd;
    }
    if (video.progress === video.progressEnd) {
      progress = 100;
    }

    return progress;
  };

  //get the watched videos from child(videoItem) in order to reload component when recentWatched changs
  const handleCallbackVideoData = async (childData) => {
    if (childData && childData.length > 0) {
      setRecentWatchedVideos(childData);
    }
  };

  //reload videos when recentWatched changes (if user watches video in scheduled)
  useEffect(() => {
    if (recentWatchedVideos.length > 0 && allTodayVideos.length > 0)
      setFilteredVideos(getTheFilteredVideos());
  }, [recentWatchedVideos]);

  //event to delete video from scheduled . Sends the video data to parent
  useEffect(() => {
    if (parentCallback && deleteScheduledVideo) {
      parentCallback(deleteScheduledVideo);
    }
  }, [deleteScheduledVideo]);

  return (
    <>
      {!receivedVideos || receivedVideos.length === 0 ? (
        <>
          {!loader ? (
            <Box
              maxWidth={null}
              sx={{
                px: { xs: '27px', md: '50px' },
                mt: 5,
              }}
            >
              {' '}
              No Scheduled Videos
            </Box>
          ) : (
            <Box
              maxWidth={null}
              sx={{
                px: { xs: '27px', md: '50px' },
                mt: 5,
              }}
            >
              {' '}
              <CircularProgress />
            </Box>
          )}
        </>
      ) : (
        <>
          <Box
            maxWidth={null}
            sx={{
              px: { xs: '27px', md: '50px' },
              marginLeft: 'unset',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    mt: { md: '6em', xs: '3em' },
                  }}
                >
                  <Typography
                    component="p"
                    variant="h1"
                    sx={{
                      fontSize: '1.3em',
                      letterSpacing: ' 0.15px',
                      marginBottom: '1em',
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {receivedVideos && receivedVideos.length > 0 ? (
              <Paper
                sx={{
                  maxWidth: '100%',
                  width: '100%',
                  display: 'flex',
                  overflowX: 'auto',
                  pb: '10px',
                  scrollBehavior: 'smooth',
                  background: 'transparent',
                }}
                elevation={0}
              >
                <Stack
                  direction="row"
                  spacing={'30px'}
                  sx={{ flexWrap: 'nowrap', rowGap: 1 }}
                >
                  {receivedVideos.map((vid, i) => {
                    return (
                      <Item
                        className={
                          todaySelectedHour.id === vid.scheduled_id
                            ? !vid.old
                              ? 'active'
                              : 'vidOldBold'
                            : !vid.old
                            ? ''
                            : 'vidOld'
                        }
                        key={i}
                        onClick={() => {
                          setTodaySelectedHour({
                            id: vid.scheduled_id,
                            watched: false,
                          });
                        }}
                      >
                        {vid.scheduled}
                      </Item>
                    );
                  })}
                </Stack>
              </Paper>
            ) : (
              ''
            )}
          </Box>
          <Box
            maxWidth={null}
            sx={{
              px: {
                xs: '27px',
                md: '50px',
              },
              pt: '26px',
              pb: '80px',
              marginLeft: 'unset',
              background: '#F3F3F5',
            }}
          >
            <Paper
              sx={{
                maxWidth: '100%',
                width: '100%',
                display: 'flex',
                overflowX: 'auto',
                pb: '10px',
                scrollBehavior: 'smooth',
                background: 'transparent',
                background: 'transparent',
              }}
              elevation={0}
            >
              <Grid
                container
                spacing={0}
                mt={0}
                sx={{
                  flexWrap: 'nowrap',
                  justifyContent: { xs: 'center', sm: 'start' },
                }}
              >
                {filteredVideos && filteredVideos.length > 0 ? (
                  <>
                    {filteredVideos.map((vid, i) => {
                      return (
                        <Grid
                          key={i}
                          item
                          xs={12}
                          sm={12}
                          md={7}
                          lg={5}
                          sx={{
                            flex: '1 0 100%!important',
                            maxWidth: { xl: '593px!important' },
                          }}
                        >
                          <Box
                            sx={{
                              width: '100%',
                              height: '100%',
                              position: 'relative',
                            }}
                          >
                            <VideoItem
                              vid={vid}
                              pageType={'schedule'}
                              isRecent={vid.watched}
                              progress={handleProgressBar(vid)}
                              parentCallback={handleCallbackVideoData}
                            />
                            <Tooltip
                              title="Remove Video"
                              sx={{
                                position: 'absolute',
                                top: '14px',
                                right: '14px',
                                zIndex: '2',
                                backgroundColor:
                                  theme.palette.common.white,

                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderColor:
                                  theme.palette.warning.main,
                                '&:hover': {
                                  backgroundColor:
                                    theme.palette.common.white,
                                },
                              }}
                            >
                              <IconButton
                                aria-label="remove video"
                                variant="contained"
                                color={'error'}
                                size="small"
                                onClick={() => {
                                  setDeleteScheduledVideo({
                                    scheduled_id: vid.scheduled_id,
                                    video_title: vid.title,
                                    hour: vid.scheduled,
                                    scheduled_date: daysArray.find(
                                      (x) => x.day === activeDay
                                    ).full,
                                  });
                                }}
                              >
                                <CloseIcon
                                  htmlColor={
                                    theme.palette.warning.main
                                  }
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Paper>
          </Box>
        </>
      )}
    </>
  );
};

export default ScheduleMobile;
