import { Box, Typography } from '@mui/material';
import React from 'react';

const NotFound = () => {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant={'h3'} component={'h3'} align={'center'}>
        Page Not Found
      </Typography>
    </Box>
  );
};

export default NotFound;
