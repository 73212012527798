import React, { useContext } from 'react';
import { Typography, Box } from '@mui/material';
import PageContext from '../../../context/page/PageContext';
import { useTheme } from '@emotion/react';

 function Privacy() {

  const {  page } = useContext(PageContext);
  const theme = useTheme();

  return (
    <Box
    sx={{
      ...theme.typography.body2,
      width: '100%',
      color:'white.secondary'
     
    }}
  >
  <Typography
      sx={{
        fontSize: { xs: '1.5rem', lg: '2rem' },
        lineHeight: '1.25em',
        fontWeight: '800',
        mb: 3,
      }}
      color={'white.main'}
    >
      Privacy Policy
    </Typography>

    {page && page.privacy_policy? (
      
      <div  dangerouslySetInnerHTML={{__html: page.privacy_policy}} />
      
      ):('')}
  </Box>
  );
}
export default Privacy;