import React, { useContext } from 'react';
import { Typography, Box } from '@mui/material';
import PageContext from '../../../context/page/PageContext';
import { useTheme } from '@emotion/react';

function TermsAndConditions() {

  const {  page } = useContext(PageContext);
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...theme.typography.body2,
        width: '100%',
        color:'white.secondary'
       
      }}
    >
    <Typography
        sx={{
          fontSize: { xs: '1.5rem', lg: '2rem' },
          lineHeight: '1.25em',
          fontWeight: '800',
          mb: 3,
        }}
        color={'white.main'}
      >
        Terms And Conditions
      </Typography>

      {page && page.terms_and_conditions? (
        
        <div  dangerouslySetInnerHTML={{__html: page.terms_and_conditions}} />
        
        ):('')}
    </Box>
  );
}

export default TermsAndConditions;
