import React, { useContext } from 'react';

function LoginAs() {
    const urlParams = new URLSearchParams(window.location.search);
    if(!urlParams.get('token')){
        localStorage.removeItem('auth-token');
        localStorage.removeItem('verified');
        window.location.href = '/';
    }else{
        let token = urlParams.get('token');
        let decodedString = atob(token);
        let params = JSON.parse(decodeURIComponent(decodedString));
        localStorage.setItem('auth-token', params.token);
        localStorage.setItem('verified', params.verified);
        window.location.href = '/';
    }
}

export default LoginAs;
