import React, { useState, useRef, useEffect } from 'react';
import {
  // Link,
  // Link as RouterLink,
  useParams,
} from 'react-router-dom';

import moment from 'moment';
import ReactPlayer from 'react-player';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import ReplayIcon from '@mui/icons-material/Replay';
import PauseIcon from '@mui/icons-material/Pause';
import Slider from '@mui/material/Slider';

import { alpha, Box } from '@mui/system';
import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@emotion/react';

//ct icons
// import { ReactComponent as ArrowLeft } from '../../../../../assets/icons/arrow-left.svg';
// import { ReactComponent as MinimizeCircle } from '../../../../../assets/icons/minimize-circle.svg';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import {
  ScreenOrientation,
  OrientationType,
} from '@capawesome/capacitor-screen-orientation';

const WorkoutVideoLandscape = ({
  category,
  videoPlaying,
  videoSessionNo,
  allTags,
  orientationLandscape,
  totalVideos,
  parentCallback,
  allVideos,
}) => {
  const params = useParams();

  const theme = useTheme();
  //detect if it's touch device
  const [touch, setTouch] = useState();

  //reactPlayer
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState();
  const [isReady, setIsReady] = useState(false);
  const playerRef = useRef();
  const [open, setOpen] = useState(false);
  const [circularWidth, setCircularWidth] = useState(false);
  const [knobSize, setKnobSize] = useState(false);
  //detect sm breakPoint
  const breakPointMD = useMediaQuery(theme.breakpoints.down('md'));

  //save the state of the video that is splaying
  const [videoState, setVideoState] = useState({
    id: false,
    progress: 0,
    timestamp: 0,
  });

  // keep video seek state at it was leaved
  const onReady = React.useCallback(() => {
    //get current video id
    const videoID = playerRef.current.props.dataid;
    //find if this video is in watchedVideos
    findStoredVideo(videoID);

    setIsReady(true);
  }, [isReady]);

  const handleClickOpen = () => {
    if (!open && isReady) {
      if (Math.ceil(videoState.progress) >= videoState.progressEnd) {
        // setProgress(0);
        // setVideoState({ ...videoState, progress: 0 });
        playerRef.current.seekTo(0, 'seconds');
        //setOpen(true);
      }
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  //search the id in stored videos
  const findStoredVideo = (videoID) => {
    //get all watched videos
    const allVideos = JSON.parse(
      localStorage.getItem('videosWatched')
    );
    if (!allVideos) return;
    let obj = allVideos.find((item) => item.id === videoID);
    if (obj && allVideos) {
      let time = 0;
      if (obj.progress) {
        if (obj.progress !== obj.progressEnd) {
          time = obj.progress;
        }

        setVideoState(obj);
        setProgress(handleProgress());
        //set seekbar to current progress from stored videos
        playerRef.current.seekTo(time, 'seconds');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', (event) => {
      if (parentCallback) {
        parentCallback({ open: open });
      }
    });
  });

  //action on videostate changing
  useEffect(() => {
    if (videoState) {
      setProgress(handleProgress());
      if (Math.round(videoState.progress) >= videoState.progressEnd) {
        setOpen(false);
      }
    }

    if (videoState.id) {
      let localStorageVideos = [];

      if (
        localStorage.getItem('videosWatched') === 'undefined' ||
        localStorage.getItem('videosWatched') === null
      ) {
        //if somehow this gets undefined, reset localstorage item
        localStorage.removeItem('videosWatched');
      } else if (localStorage.getItem('videosWatched')) {
        //if the videos are in local storage

        //get videos from local storage
        localStorageVideos = JSON.parse(
          localStorage.getItem('videosWatched')
        );
      }

      if (localStorageVideos.length < 1) {
        //if local storage has no videos, add curent video
        localStorageVideos.push(videoState);
      } else {
        //here you set the maximum videos that should be stored
        if (localStorageVideos.length > 5) {
          localStorageVideos.shift(); //removes the first video
        }

        localStorageVideos = renderDuplicates(
          localStorageVideos,
          videoState
        );
      }

      //set local storage videos
      localStorage.setItem(
        'videosWatched',
        JSON.stringify(localStorageVideos)
      );
    }
  }, [videoState]);

  const renderDuplicates = (localStorageVideos) => {
    let storedVideos = localStorageVideos;

    //find if there is a video with the same id
    let obj = storedVideos.find((item) => item.id === videoState.id);
    let index = storedVideos.indexOf(obj);

    if (obj) {
      storedVideos[index] = videoState;
    } else {
      //if there is not video with same id, add it to array
      storedVideos.push(videoState);
    }
    //if local storage has videos , filter them to avoid adding dupplicates
    localStorageVideos = storedVideos;
    return localStorageVideos;
  };

  //get the category
  useEffect(() => {
    //reset data
    setProgress(0);

    setVideoState({
      id: false,
      progress: 0,
      timestamp: 0,
    });
    setOpen(false);

    //detect if device was touched
    window.addEventListener('touchstart', function () {
      setTouch(true);
    });
  }, [videoPlaying]);

  //handle circular Slider seek - sync with video
  const handleChange = (event, value) => {
    //playerRef.current.seekTo(handleValue(value), 'seconds');
    setProgress(value);

    //
  };

  useEffect(() => {
    if (progress && isReady && !open) {
      playerRef.current.seekTo(handleValue(progress), 'seconds');
    }
  }, [progress]);

  //convert vid secons to min&sec
  const convertSecondsInMinutes = (elSeconds) => {
    const date = new Date(elSeconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad(date.getUTCSeconds());

    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
  };
  function pad(string) {
    return ('0' + string).slice(-2);
  }

  //set the circular sizes based on resolution
  useEffect(() => {
    if (!breakPointMD) {
      setKnobSize(55);
      setCircularWidth(150);
    } else {
      setCircularWidth(120);
      setKnobSize(50);
    }
  }, [breakPointMD]);

  //handle the circular slider progress- transform seconds to procent
  const handleProgress = (receivedValue) => {
    let progress = 0;

    if (videoState.id) {
      if (videoState.progress > 0) {
        progress =
          (videoState.progress * 359) / videoState.progressEnd;
      }
      if (videoState.progress >= videoState.progressEnd) {
        progress = 359;
      }
    }
    return Math.round(progress);
  };

  //handle the circular slider value to show on label - transform procent to seconds
  const handleValue = (value) => {
    let videoValue;

    if (value) {
      videoValue = (videoState.progressEnd * value) / 359;
    }
    if (videoValue >= videoState.progressEnd) {
      videoValue = videoState.progressEnd - 0.001;
    }

    return videoValue;
  };

  //lock &unlock orientation

  const lock = async () => {
    try {
      await ScreenOrientation.lock({
        type: OrientationType.LANDSCAPE,
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const unlock = async () => {
    try {
      await ScreenOrientation.unlock();
      return true;
    } catch (error) {
      return false;
    }
  };

  const btnStyle = {
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: {
      xs: '1rem',
      sm: '1.5rem',
      md: '1.8rem',
      lg: '2.1rem',
    },
    padding: {
      xs: '24px 15px',
      md: '28px 15px',
      lg: '38px 15px',
      xl: '45px 15px',
    },
    lineHeight: '1.2em',
    letterSpacing: '0.24px',
    borderRadius: '50px',
    height: 'auto',
    textTransform: 'none',
    boxShadow: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    display: 'block',
    textAlign: 'center',
    maxWidth: '600px',
  };

  const changeOrientation = () => {
    let isInFullScreen =
      (document.fullscreenElement &&
        document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement &&
        document.msFullscreenElement !== null);

    let docElm = document.documentElement;
    if (!isInFullScreen && !orientationLandscape) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      } else if (docElm.webkitEnterFullscreen) {
        docElm.webkitEnterFullscreen();
      }

      if (lock() && isReady) lock();
      if (parentCallback) {
        parentCallback({ orientationLandscape: true });
      }
    } else if (orientationLandscape) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }

      if (unlock() && isReady) unlock();
      if (parentCallback) {
        parentCallback({ orientationLandscape: false });
      }
    }
  };

  const getVideoIndexAllVideos = (id) => {
    return allVideos.findIndex((video) => video.id === id);
  };

  return (
    <>
      {' '}
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexGrow: { xs: 0, md: 1 },
            height: { xs: 'auto', md: '100%' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              flex: 0,
              position: 'fixed',
              p: '36px 20px',
              top: '0px',
              left: '0px',
              zIndex: 99999,
              width: '100vw',
              height: '100vh',
              backgroundColor: alpha(theme.palette.common.black, 0.2),
              color: theme.palette.common.white,
              transition: 'all 0.5s ease-in',
              opacity: open ? '0' : '1',
            }}
          >
            {/* header */}
            <Box
              className="ctHeader"
              sx={{
                width: '100%',
                flexGrow: 0,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: '24px',
                }}
                spacing={'12px'}
              >
                <Stack
                  direction="row"
                  sx={{
                    alignItems: 'center',
                  }}
                  spacing={'12px'}
                >
                  <Box
                    sx={{
                      width: '100%',
                      maxWidth: '100%',
                      display: 'inline-grid',
                    }}
                  >
                    <Typography
                      variant="h1"
                      color="white"
                      sx={{
                        fontSize: { xs: '22px', sm: '28px' },
                        lineHeight: '1.25em',
                        fontWeight: '700',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                        mb: 0,
                      }}
                    >
                      {videoPlaying.title}
                    </Typography>
                  </Box>
                </Stack>

                <Stack
                  direction="row"
                  sx={{ alignItems: 'center' }}
                  spacing={'12px'}
                >
                  <Typography
                    color="primary"
                    sx={{
                      fontSize: { xs: '22px', sm: '28px' },
                      fontFamily: theme.typography.roboto,
                      p: '0',
                      textTransform: 'none',
                      height: 'auto',
                      fontWeight: '700',
                      letterSpacing: '0.15px',
                      lineHeight: '1.2em',
                      color: theme.palette.primary.main,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {getVideoIndexAllVideos(videoPlaying.id) + 1}{' '}
                    <Typography
                      component="span"
                      color="white"
                      sx={{
                        fontFamily: 'inherit',
                        fontSize: 'inherit',
                        fontWeight: 'inherit',
                        color: theme.palette.common.white,
                      }}
                    >
                      / {totalVideos}
                    </Typography>
                  </Typography>
                  <Tooltip
                    title="Exit Fullscreen"
                    sx={{
                      width: 'auto',
                      height: 'auto',
                      backgroundColor: 'transparent',
                      color: theme.palette.primary.main,
                      fontSize: '24px',
                      p: '10px 0px',
                      fontFamily: theme.typography.roboto,
                      '& svg': {
                        color: theme.palette.primary.main,
                        fill: theme.palette.primary.main,
                        width: '31px',
                        height: '31px',
                      },
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: theme.palette.common.white,
                      },
                      '&:hover svg': {
                        color: theme.palette.common.white,
                        fill: theme.palette.common.white,
                      },
                    }}
                    onClick={changeOrientation}
                  >
                    <IconButton
                      aria-label="Close Fullscreen"
                      variant="outlined"
                      size="small"
                    >
                      <CloseFullscreenIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Box>
            {/* center */}
            <Stack
              direction="column"
              sx={{
                alignItems: 'center',
                flexGrow: '1',
                width: '100%',
                justifyContent: 'center',
              }}
              spacing={'12px'}
              onClick={() => (open ? setOpen(false) : setOpen(true))}
            >
              {!isReady ? <CircularProgress /> : ''}
            </Stack>
            {/* footer */}
            <Stack
              direction="row"
              spacing={'2rem'}
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {isReady ? (
                <>
                  <Typography
                    component="span"
                    color="primary"
                    sx={{
                      fontFamily: theme.typography.roboto,
                      fontSize: { xs: '22px', md: '36px' },
                      fontWeight: '700',
                      flexShrink: 0,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {videoState.progress > 0 && isReady
                      ? convertSecondsInMinutes(videoState.progress)
                      : duration
                      ? convertSecondsInMinutes(duration)
                      : '0.00'}{' '}
                    min
                  </Typography>
                  {videoState.id ? (
                    <Slider
                      value={progress}
                      min={0}
                      step={1}
                      max={359}
                      onChange={handleChange}
                      sx={{ pt: 5 }}
                      valueLabelDisplay="off"
                      aria-labelledby={`${
                        videoState.progress > 0
                          ? convertSecondsInMinutes(
                              videoState.progress
                            )
                          : convertSecondsInMinutes(duration)
                      }`}
                    />
                  ) : (
                    ''
                  )}
                  <Button
                    aria-label="Play"
                    variant="contained"
                    size="large"
                    color="primary"
                    sx={{
                      borderRadius: '50%',
                      width: '64px',
                      height: '64px',
                      minWidth: '64px',
                      p: 0,
                      '& svg': {
                        width: '30px',
                        height: '30px',
                        fontSize: '30px',
                      },
                    }}
                    onClick={handleClickOpen}
                  >
                    {!open ? (
                      Math.ceil(videoState.progress) >=
                      videoState.progressEnd ? (
                        <ReplayIcon />
                      ) : (
                        <PlayArrowIcon />
                      )
                    ) : (
                      <PauseIcon />
                    )}
                  </Button>
                </>
              ) : (
                ''
              )}
            </Stack>
          </Box>
          {/* content */}
          <Box
            className="ctContent"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'start',
              flexGrow: '1',
              width: '100%',
              flexGrow: 1,
              m: '0px',
              height: '100vh',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                width: '100%',
                flexGrow: 1,

                width: '100%',
                height: '100%',
              }}
            >
              {!videoPlaying ? (
                <CircularProgress />
              ) : (
                <ReactPlayer
                  className="videoR"
                  url={`https://www.vimeo.com/${videoPlaying.vimeo_id}`}
                  controls={false}
                  width="100%"
                  height="100%"
                  pip={true}
                  stopOnUnmount={false}
                  playsinline={true}
                  playsInline={true}
                  config={{
                    vimeo: {
                      playerOptions: {
                        playsinline: 1,
                        controls: 0,
                        fullscreen: 0,
                        allowfullscreen: 0,
                      },
                    },
                  }}
                  onPause={() => setOpen(false)}
                  onPlay={() => setOpen(true)}
                  onProgress={(barprogress) => {
                    if (isReady & open) {
                      setVideoState({
                        ...videoState,
                        id: videoPlaying.id,
                        progress: barprogress.playedSeconds,
                        timestamp: moment().valueOf(),
                      });
                    }
                  }}
                  // onProgress={setPlayerState}
                  // onEnded={() => setPlaying(false)}
                  onDuration={(duration) => {
                    if (isReady) {
                      setDuration(duration);
                      setVideoState({
                        ...videoState,
                        progressEnd: duration,
                      });
                    }
                  }}
                  // ref={player}
                  ref={playerRef}
                  playing={open}
                  onReady={onReady}
                  dataid={videoPlaying.id}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WorkoutVideoLandscape;
