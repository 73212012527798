import React from 'react';

import {
  Chip,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as CaloriesIcon } from '../../assets/icons/calories.svg';
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import { useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';

const VideoDetails = ({ vid }) => {
  const theme = useTheme();

  //css
  const BottomSideClass = {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& .meta-left': {
      width: '100%',
      // max-width: '50%',
    },
    '& h3': {
      //font-size: '1.5rem',
      margin: '0',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& h6': {
      fontSize: '1.5rem',
      margin: '0',
      maxWidth: '45%',
    },
    '& p': {
      margin: '0 0 .25rem',
    },
  };
  const detailsContainer = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    padding: {
      xs: '14px 14px 20px 14px',
      lg: '14px 14px 20px 14px',
      xl: '16px 16px 24px 16px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  const Item = styled('span')(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.white.main,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  }));

  const topBottomSides = {
    position: 'relative',
    zIndex: '1',
    color: '#fff',
  };

  return (
    <Box sx={detailsContainer}>
      <Box className="top-side" sx={topBottomSides}>
        <Stack
          direction="row"
          spacing={1}
          sx={{ flexWrap: 'wrap', rowGap: 1 }}
        >
          {vid.tags.map((tag, i) => {
            return (
              <Chip
                size="small"
                variant="outlined"
                label={tag}
                key={i}
                sx={{
                  backgroundColor: '#969696',
                  color: '#fff',
                  border: '0px',
                  padding: '1em',
                  fontSize: {
                    xs: '12px',
                    md: '13px',
                    xl: '15px',
                  },
                  textTransform: 'capitalize',
                }}
              />
            );
          })}
        </Stack>
      </Box>
      <Box
        className="bottom-side"
        sx={(BottomSideClass, topBottomSides)}
      >
        <Typography
          component="h1"
          className="title"
          sx={{
            fontSize: {
              xs: '18px',
              sm: '19px',
              md: '21px',
              xl: '28px',
            },
            margin: '0',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {vid.title}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexGrow: '1',
          }}
        >
          <Box sx={{ flexGrow: '1' }}>
            <Typography
              component="p"
              className="vidAuthor"
              sx={{
                opacity: 0.8,
                fonWeight: '400',
                fontSize: '18px',
              }}
            >
              {vid.author}
            </Typography>
            <Box>
              <Stack
                direction="row"
                spacing={'12px'}
                sx={{
                  fontSize: {
                    xs: '14px',
                    md: '14px',
                    lg: '15px',
                    xl: '18px',
                  },
                  alignItems: 'center',
                }}
              >
                <Item className="calories">
                  <CaloriesIcon />
                  <Typography
                    component="span"
                    variant="p"
                    sx={{ fontWeight: 'bolder' }}
                  >
                    {vid.kcal}
                  </Typography>
                  <Typography
                    component="span"
                    variant="p"
                    sx={{ fontWeight: 'normal' }}
                  >
                    kcal
                  </Typography>
                </Item>
                <Item className="time">
                  <Clock />
                  <Typography
                    component="span"
                    variant="p"
                    sx={{ fontWeight: 'bolder' }}
                  >
                    {Math.ceil(vid.length/60)}
                  </Typography>
                  <Typography
                    component="span"
                    variant="p"
                    sx={{ fontWeight: 'normal' }}
                  >
                    min
                  </Typography>
                </Item>
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexShrink: '0',
              flexGrow: '1',
              textAlign: 'right',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <Typography
              component="h4"
              variant="h4"
              sx={{
                textTransform: 'lowercase',
                fontSize: {
                  xs: '20px',
                  md: '32px',
                  lg: '20px',
                  xl: '42px',
                },
              }}
            >
              {vid.scheduled}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VideoDetails;
