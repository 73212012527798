import React, { useState } from 'react';
import {
  Chip,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Grow,
  Skeleton,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';

export default function CategoryItem({ i, data }) {
  const [ready, setReady] = useState(false);
  const handleReady = () => {
    setReady(true);
  };
  const heights = { lg: '200px', md: '260px', xs: '90px' };

  return (
    <>
      {!ready && (
        <Skeleton
          variant="rectangular"
          sx={{
            height: heights,
          }}
          width="100%"
        />
      )}
      <Grow in={ready} timeout={i}>
        <div>
          <Card
            sx={{
              boxShadow: 'none',
              // position: ready ? 'relative' : 'absolute',
              overFlow: 'hidden',
              borderRadius: '10px',
              // height: heights,
              height: 'auto',
              paddingBottom: '63%',
              position: 'relative',
              width: '100%',
            }}
          >
            <CardActionArea
              sx={{
                position: 'absolute',
                left: '0px',
                top: '0px',
                width: '100%',
                height: '100%',
              }}
              component={Link}
              to={`/workout/${data.id}`}
            >
              <img
                onLoad={handleReady}
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                }}
                src={
                  data.thumbnail
                    ? data.thumbnail
                    : 'https://source.unsplash.com/random?fitness ' +
                      data.title
                }
                alt={data.title}
              />
              <CardContent
                sx={{
                  position: 'absolute',
                  color: '#fff',
                  zIndex: 3,
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  backgroundImage:
                    'linear-gradient(transparent, rgba(0,0,0,.5))',
                  fontSize: '.9em',
                  padding: { xs: '11px', md: '16px' },
                }}
              >
                <Stack
                  direction="row"
                  sx={{ alignItems: 'center', flexWrap: 'wrap' }}
                  spacing={1}
                >
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      fontSize: { xs: '0.89rem' },
                    }}
                  >
                    <strong>{data.title}</strong>
                  </Typography>
                  {data.is_new && (
                    <Chip
                      label="New"
                      style={{
                        backgroundImage:
                          'linear-gradient(90deg,rgb(251, 103, 93) 25%, rgb(228, 64, 135) 84%)',
                        color: '#fff',
                      }}
                      size="small"
                      variant="filled"
                      sx={{ transform: 'scale(.8)' }}
                    />
                  )}
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Grow>
    </>
  );
}
