import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Paper,
  Box,
  Grid,
  Typography,
  Button,
  alpha,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import PageContext from '../../context/page/PageContext';

export default function HomeAuth() {
  const theme = useTheme();
  const {page}= useContext(PageContext)

  const isRegister =  page && page.access_register_page;
  //btn  style
  const btnStyle = {
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: { xs: '16px', md: '35px' },
    lineSeight: '100%',
    letterSpacing: '0.24px',
    borderRadius: '50px',
    height: { xs: '64px', md: '98px' },
    textTransform: 'none',
  };
  return (
    <Grid
      container
      sx={{
        flexGrow: '1',
        position: 'relative',
        alignItems: 'stretch',
        height: '100%',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'flex-end',
      }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        lg={7}
        sx={{
          position: { xs: 'fixed', sm: 'relative' },
          bottom: { xs: '0px', sm: 'initial' },
          width: { xs: '100%', sm: 'initial' },
          backgroundImage:
            'url(https://source.unsplash.com/random?fitness)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: { xs: '100vh', sm: 'auto' },
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          position: 'relative',
          zIndex: 1,
          background: {
            xs: `linear-gradient(180deg,  ${alpha(
              theme.palette.blackBg.main,
              0
            )} 0%, ${theme.palette.blackBg.main} 47.84%)`,

            sm: theme.palette.common.black,
          },
          boxShadow: 'none',
          display: 'flex',
          alignItems: { xs: 'flex-end', sm: 'center' },
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            my: 8,
            mx: { xs: '20px', md: 4 },
            mb: { xs: '32px', md: '64px' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexGrow: '1',
            height: { sm: isRegister ?  '40%' : '30%', md: isRegister ?  '60%' : '40%'},
            maxWidth: '513px',
            maxHeight: '619px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            {/* <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LoginIcon />
                </Avatar> */}
            <Typography
              component="h1"
              variant="h1"
              sx={{
                color: 'white.main',
                fontSize: { xs: '40px', md: '53px' },
              }}
              align="center"
              mb={{ xs: 1, md: 4 }}
            >
              Fitness at Home
            </Typography>

            <Typography
              variant="body2"
              color={'white.secondary'}
              sx={{
                fontSize: { xs: '16px', md: '29px' },
              }}
              align="center"
              mb={{ xs: '20px', md: 4 }}
            >
              Spend some fun time at home
              <br />
              with video training anywhere
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
          {isRegister && 
              <Button
                variant="contained"
                size="large"
                component={RouterLink}
                to="/sign-up"
                fullWidth
                sx={[btnStyle, { mb: { xs: '20px', md: 3 } }]}
              >
                Sign Up
              </Button>
            }
            <Button
              variant="outlined"
              size="large"
              component={RouterLink}
              to="/login"
              fullWidth
              sx={[
                btnStyle,
                { color: 'white.main', borderColor: 'white.main' },
              ]}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
