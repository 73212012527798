import React, { useContext } from 'react';
import AuthContext from '../context/auth/AuthContext';
import Categories from './categories/Categories';
import HomeAuth from './auth/HomeAuth';
import { Box } from '@mui/material';
import TempPassword from "./TempPassword";

function Home() {
  const { user,verified } = useContext(AuthContext);
  
  return (
    <Box
      fullWidth
      sx={{
        display: 'flex',
        height: '100%',
      }}
    >
      {!user ? (
        <HomeAuth></HomeAuth>
      ) : (
        <>
            {verified ? (<Categories></Categories>) : <TempPassword/>}
        </>
      )}
    </Box>
  );
}

export default Home;
