import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';

function ConfirmDialog(props) {
  const theme = useTheme();

  const [message, setMessage] = useState(
    props.dialogData.message ? props.dialogData.message : false
  );
  const [headerTitle, setHeaderTitle] = useState(
    props.dialogData.title ? props.dialogData.title : false
  );
  const [dataReceived, setDataReceived] = useState(
    props.dialogData.dataToSend ? props.dialogData.dataToSend : false
  );

  const [showCloseIcon, setShowCloseIcon] = useState();
  const [confirmButtonText, setConfirmButtonText] =
    useState('Confirm');

  const [fullscreen, setFullscreen] = useState(
    'fullscreen' in props.dialogData
      ? props.dialogData.fullscreen
      : false
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.dialogData.onSubmit ? true : false);
    setMessage(
      props.dialogData.message
        ? props.dialogData.message
        : 'Are you sure ?'
    );
    setHeaderTitle(
      props.dialogData.title
        ? props.dialogData.title
        : 'Confirm the action'
    );
    setDataReceived(
      props.dialogData.dataToSend ? props.dialogData.dataToSend : ''
    );
    setShowCloseIcon(
      'showCloseIcon' in props.dialogData
        ? props.dialogData.showCloseIcon
        : true
    );
    setConfirmButtonText(
      props.dialogData.confirmButtonText
        ? props.dialogData.confirmButtonText
        : 'Confirm'
    );
    setFullscreen(
      'fullscreen' in props.dialogData
        ? props.dialogData.fullscreen
        : false
    );
  }, [props]);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      fullScreen={fullscreen}
    >
      <DialogTitle>{headerTitle}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        {showCloseIcon ? (
          <IconButton
            onClick={() => {
              if (props.dialogData.onCancel)
                props.dialogData.onCancel();
              setOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          ''
        )}
      </Box>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button
          color="gray"
          variant="contained"
          onClick={() => {
            if (props.dialogData.onCancel)
              props.dialogData.onCancel();
            setOpen(false);
          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (open) {
              props.dialogData.onSubmit(dataReceived);
              setOpen(
                'openAfterSubmit' in props.dialogData
                  ? props.dialogData.openAfterSubmit
                  : false
              );
            }
          }}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
