const authReducer = (state, action) => {
	switch (action.type) {
		case 'SET_PAGE':
			return {
				...state,
				page: action.payload,
				loading: false,
			};
		case 'SET_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};

export default authReducer;
