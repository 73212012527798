import React from 'react';
import { Grid, Box, alpha } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Privacy from './components/Privacy';
import TermsAndConditions from './components/TermsAndConditions';
import Legal from '../../components/Legal';
import { useTheme } from '@emotion/react';

function ContainerLegal() {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        flexGrow: '1',
        position: 'relative',
        alignItems: 'stretch',
        height: '100%',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'flex-end',
      }}
    >
      <Grid item xs={12} sm={4} md={6} lg={7}>
        <Box
          sx={{
            backgroundImage:
              'url(https://source.unsplash.com/random?fitness)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: { xs: '100vh', sm: '100%' },
            position: 'fixed',
            top: '0',
            width: '100%',
            maxWidth: 'inherit',
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={5}
        sx={{
          position: 'relative',
          zIndex: 1,
          background: {
            // xs: 'linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, rgba(32, 32, 32, 0.7) 102px, #181818 47.84%)',
            // sm: '#000',
            xs: `linear-gradient(180deg,  ${alpha(
              theme.palette.blackBg.main,
              0
            )} 0%, ${alpha(theme.palette.blackBg.main, 0.7)} 102px, ${
              theme.palette.blackBg.main
            } 47.84%)`,

            sm: theme.palette.common.black,
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexGrow: '1',
            height: '100%',
          }}
        >
          <Box
            sx={{
              mx: { xs: '20px', md: 4 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: '1',
            }}
          >
            <Box
              sx={{
                my: 8,

                mb: { xs: '12px', md: '64px' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: {
                  xs: 'flex-end',
                  sm: 'center',
                },
                flexGrow: '1',
                maxWidth: '700px',
                width: '100%',
              }}
            >
              <Routes>
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<Privacy />} />{' '}
              </Routes>
            </Box>
          </Box>

          <Box
            sx={{
              my: 1,
              mx: 3,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'end',
            }}
          >
            <Legal />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ContainerLegal;
