import React, { useEffect, useMemo } from 'react';
import { createContext, useReducer } from 'react';
const FunctionsContext = createContext();

// export const functions = ({ children }) => {};

// export default functions;

export function isLight(color) {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +(
      '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
    );

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 210.5) {
    return true;
  } else {
    return false;
  }
}

export function ifMainColorIsLight(theme) {
  return isLight(theme.palette.primary.main)
    ? isLight(theme.palette.primary.contrastText)
      ? theme.palette.iconDarkDefault.main
      : theme.palette.primary.contrastText
    : theme.palette.primary.main;
}
